export const schema = [
    {
        description: 'exercice',
        title: "Période de l'Exercice",
        className: 'border-bottom'
    },
    {
        description: 'operation',
        title: 'Opération',
        className: 'border-bottom'
    },
    {
        description: 'type',
        title: 'Budget',
        className: 'border-bottom'
    },
    {
        title: 'Type',
        description: 'typeBudget',
        className: 'border-bottom'
    },
    {
        description: 'estPrevisionnel',
        title: 'Action sur le Prévisionnel',
        isBoolean: true,
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        title: 'Date de création',
        description: 'dateCreation',
        isDate: true,
        className: 'border-bottom'
    },
    {
        description: 'dateDerniereModification',
        title: 'Modification',
        isDate: true
    }
];
