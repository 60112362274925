import { useCallback } from 'react';
import {
    fetchElement,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import {
    checkPlageDate,
    FORM_CONST,
    formInitialState,
    TODAY
} from '@napp-inc/jnapp-util';
import { URL_CONST } from '../../../../util';
import {
    CustomDropdown,
    CustomRow,
    SuperDealerSelector
} from '../../../../components';
import { MainReportComponentLarge } from '../../releve/generic';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false,
    selectedSuperDealer: {},
    selectedDevise: {},
    selectedType: { code: 'ACTIF' },
    selectedSource: { code: 'EST_BD', designation: 'Plateforme JNAPPS' }
};

export function RapportDepotBaseKinDBForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, nappUser, superDealerKinDB } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            superDealerKinDB: reduxState.allSuperDealerKinDB.map((item) => ({
                ...item,
                code: item.entreprise.code,
                designation: item.entreprise.designation
            }))
        })
    );
    const { idToken } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements } = formState;
    const getPrefixSuperDealer = useCallback(() => {
        const superDealerElement = superDealerKinDB.find(
            (item) => item.code === fields.selectedSuperDealer.code
        );
        if (superDealerElement?.estPrefix) {
            const {
                monnaie: { devises }
            } = superDealerElement;
            return {
                debutNumero: devises?.[0]?.numero,
                finNumero: `${devises?.[0]?.numero}999999`,
                codeMarchand: superDealerElement?.marchand?.code
            };
        }
        return { codeMarchand: superDealerElement?.marchand?.code };
    }, [fields.selectedSuperDealer.code, superDealerKinDB]);
    const fetchReport = () => {
        const { dateFin, dateDebut } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const prefixElement = getPrefixSuperDealer();
        const payload = {
            dateDebut,
            dateFin,
            codeMarchand: prefixElement?.codeMarchand,
            estMax: true
        };
        if (prefixElement.debutNumero && prefixElement.finNumero) {
            payload.debutNumero = prefixElement.debutNumero;
            payload.finNumero = prefixElement.finNumero;
            payload.estPrefix = true;
        }
        if (fields.selectedSource.code === 'EST_FICHIER') {
            payload.estClientFichier = true;
        }
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_CLIENT_MARCHAND_EVOLUE,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher
            })
        );
    };

    const reduceData = (data, items) =>
        items.reduce((acc, curr) => acc + (data.devises[curr] || 0), 0);

    const filterData = useCallback(
        (data, keys) => {
            if (fields.selectedType.code === 'ACTIF')
                return data.filter((item) => reduceData(item, keys));
            if (fields.selectedType.code === 'INACTIF')
                return data.filter((item) => !reduceData(item, keys));
            return data;
        },
        [fields.selectedType.code]
    );

    const formatDataFromFunction = useCallback(
        (data) => {
            const keys =
                (data || []).length && Object.keys(data?.[0]?.devises || {});
            const headerToAdd =
                (keys || []).map((item) => ({
                    displayName: item,
                    field: `devises.${item}`
                })) || [];
            return {
                data: filterData(
                    (data || []).map((item) => ({
                        ...item,
                        organisation: item.organisation || item.client
                    })),
                    keys
                ),
                mapper: [
                    {
                        field: 'tillNumber',
                        displayName: 'TillNumber'
                    },
                    {
                        field: 'organisation.designation',
                        displayName: 'Client'
                    },
                    {
                        field: 'numeroTel',
                        displayName: 'Numero'
                    },
                    {
                        field: 'agent.nom',
                        displayName: 'Agent'
                    },
                    ...headerToAdd,
                    {
                        field: 'adresse',
                        displayName: 'Adresse'
                    }
                ]
            };
        },
        [filterData]
    );

    const displayClientType = useCallback(() => {
        if (fields.selectedType.code === 'TOUS')
            return `${fields?.selectedType?.code} LES CLIENTS`;
        if (fields.selectedType.code === 'ACTIF') return `Clients Actifs`;
        return `Clients Inactifs`;
    }, [fields.selectedType.code]);

    const { data, mapper } = formatDataFromFunction(
        elements?.mouvements?.value
    );

    return (
        <MainReportComponentLarge
            listTitle={displayClientType()}
            isDataFetched={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS
            }
            isDetaille={false}
            formState={formState}
            formDispatcher={formDispatcher}
            fetchReport={fetchReport}
            mapper={mapper}
            dataList={data || []}
            isFetching={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
            }
        >
            <SuperDealerSelector
                formState={formState}
                formDispatcher={formDispatcher}
                idProperty="code"
                isAddAditionalOptions
                estNonAirtime
            />
            <CustomRow>
                <CustomDropdown
                    options={[
                        { code: 'TOUS' },
                        { code: 'ACTIF' },
                        { code: 'INACTIF' }
                    ]}
                    defaultOption="Triez client"
                    label="Activité: "
                    labelClassName="col-12"
                    divClassName="col-12"
                    formDispatcher={formDispatcher}
                    name="selectedType"
                    id="selectedTypeTargetBasePartenaireId"
                    formState={formState}
                    idProperty="code"
                    keyProperty="code"
                    propertyToDisplay="code"
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={[
                        {
                            code: 'EST_FICHIER',
                            designation: 'Fichier Partenaire'
                        },
                        { code: 'EST_BD', designation: 'Plateforme JNAPPS' }
                    ]}
                    defaultOption="Précisez source"
                    label="Source de données: "
                    labelClassName="col-12"
                    divClassName="col-12"
                    formDispatcher={formDispatcher}
                    name="selectedSource"
                    id="selectedSourceTargetBasePartenaireId"
                    formState={formState}
                    idProperty="code"
                    keyProperty="code"
                />
            </CustomRow>
        </MainReportComponentLarge>
    );
}
