/**
 * Separates a camelCase string into words separated by spaces.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.str - The camelCase string to be separated.
 * @returns {string} - The separated string with spaces between words.
 */
export function separateString({ str }) {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}
