import { useSelector } from 'react-redux';
import { Array, OPERATION_RAPPORT, TIME, TODAY } from '@napp-inc/jnapp-util';
import {
    separateString,
    URL_CONST,
    useGetReportDetail
} from '../../../../util';
import { ReduxReportTemplate } from '../../template';
// import { CustomCard } from '../../../../components';

const getDesignationOperation = (operation) => {
    const replaced = operation.replace('__Actif', '').replace('__Passif', '');
    return OPERATION_RAPPORT?.[replaced] || separateString({ str: replaced });
};

const customOrder = (data = []) => {
    const result = [];
    result.push(
        ...data.filter((item) =>
            item?.designationActif?.toLowerCase().includes('caisse')
        )
    );
    result.push(
        ...data.filter((item) =>
            item?.designationActif?.toLowerCase().includes('stock')
        )
    );
    result.push(
        ...data.filter((item) =>
            item?.designationActif?.toLowerCase().includes('balance')
        )
    );
    result.push(
        ...data.filter((item) =>
            item?.designationActif?.toLowerCase().includes('caution')
        )
    );
    result.push(
        ...data.filter((item) =>
            item?.designationActif?.toLowerCase().includes('compte')
        )
    );
    result.push(
        ...data.filter((item) =>
            item?.designationActif?.toLowerCase().includes('limit')
        )
    );
    result.push(
        ...data.filter((item) => !result.find((rs) => rs?.id === item?.id))
    );
    return result;
};

export function RapportDashboardNappForm() {
    /**
     * Get data on redux store
     */
    const { dynamicNode } = useSelector((reduxState) => ({
        dynamicNode: reduxState.dynamicNode
    }));
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedSuperDealer: {}
        }
    });
    const listFormater = (donnees) => {
        const operation = donnees?.[0]?.listReturn?.[0]?.operation || {};
        const result = Object.keys(operation).map((key) => {
            const isActif = operation[key]?.type === 'Actif';
            const propertyToAdd = {};
            if (isActif) {
                propertyToAdd.designationActif = getDesignationOperation(key);
                propertyToAdd.soldeFermetureActif =
                    operation[key]?.soldeFermeture;
                propertyToAdd.soldeOuvertureActif =
                    operation[key]?.soldeOuverture;
            } else {
                propertyToAdd.designationPassif = getDesignationOperation(key);
                propertyToAdd.soldeFermeturePassif =
                    operation[key]?.soldeFermeture;
                propertyToAdd.soldeOuverturePassif =
                    operation[key]?.soldeOuverture;
            }
            return {
                id: key,
                type: operation[key]?.type,
                ...propertyToAdd
            };
        });
        const grouped = Array.groupByProperty({
            array: result,
            property: 'type'
        });
        Array.tri({
            data: grouped?.Actif,
            property: 'designationActif',
            isString: true,
            order: 'ASC'
        });
        Array.tri({
            data: grouped?.Passif,
            property: 'designationPassif',
            isString: true,
            order: 'ASC'
        });
        grouped.Actif = customOrder(grouped?.Actif);
        const fusion = [];
        if (grouped?.Actif?.length >= grouped?.Passif?.length) {
            if (grouped?.Actif?.length)
                fusion.push(...[].concat(grouped?.Actif));
            if (grouped?.Passif?.length) {
                for (let i = 0; i < grouped.Passif.length; i += 1) {
                    fusion[i] = {
                        ...fusion[i],
                        ...grouped?.Passif?.[i]
                    };
                }
            }
        } else {
            if (grouped?.Passif?.length)
                fusion.push(...[].concat(grouped?.Passif));
            if (grouped?.Actif?.length) {
                for (let i = 0; i < grouped.Actif.length; i += 1) {
                    fusion[i] = {
                        ...fusion[i],
                        ...grouped?.Actif?.[i]
                    };
                }
            }
        }
        return fusion;
    };
    const chartDataListFormater = (data) =>
        data?.[0]?.rapportsDate?.[0]?.dates || [];

    const setValueAndApparence = (value) => {
        let appearance = 'success';
        let newValue = 0;
        if (!value || value === 0) {
            appearance = 'success';
            newValue = 0;
        }
        if (value > 0) {
            appearance = 'danger';
            newValue = value;
        }
        return {
            value: newValue,
            appearance
        };
    };
    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <div className="mb-3 btn btn-primary position-relative align-self-end">
                Opération(s) en cours...
                <span
                    className={`position-absolute top-0 start-100 translate-middle badge rounded-pill bg-${
                        setValueAndApparence(
                            dynamicNode?.[
                                `rapportDashboardNapp-${TIME.utc000000(
                                    TODAY.at000000()
                                )}-${TIME.utc235959(TODAY.at235959())}`
                            ]?.[0]?.nombreEncoursTraitement
                        )?.appearance
                    }`}
                >
                    {
                        setValueAndApparence(
                            dynamicNode?.[
                                `rapportDashboardNapp-${TIME.utc000000(
                                    TODAY.at000000()
                                )}-${TIME.utc235959(TODAY.at235959())}`
                            ]?.[0]?.nombreEncoursTraitement
                        )?.value
                    }
                    <span className="visually-hidden">
                        Opération(s) en cours
                    </span>
                </span>
            </div>
            <ReduxReportTemplate
                dynamicNodeName={`rapportDashboardNapp-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_RAPPORT_DASHBOARD_NAPP}
                listFormater={listFormater}
                formater={(data) => [data]}
                mapper={[
                    {
                        displayName: 'Actif',
                        field: 'designationActif'
                    },
                    {
                        displayName: 'Ouverture',
                        field: 'soldeOuvertureActif',
                        isTotal: true
                    },
                    {
                        displayName: 'Fermeture',
                        field: 'soldeFermetureActif',
                        isTotal: true
                    },
                    {
                        displayName: 'Passif',
                        field: 'designationPassif'
                    },
                    {
                        displayName: 'Ouverture',
                        field: 'soldeOuverturePassif',
                        isTotal: true
                    },
                    {
                        displayName: 'Fermeture',
                        field: 'soldeFermeturePassif',
                        isTotal: true
                    }
                ]}
                seriesMapper={[
                    {
                        displayName: 'Stock flash',
                        field: `operation.StockFlashs__Actif.soldeFermeture`
                    },
                    {
                        displayName: 'Balance S. Dealer',
                        field: `operation.BalancesSuperDealer__Actif.soldeFermeture`
                    },
                    {
                        displayName: 'Balance Cash Col.',
                        field: `operation.BalancesCashCollecteur__Actif.soldeFermeture`
                    },
                    {
                        displayName: 'Balance Rev. Passif',
                        field: `operation.BalancesRevendeur__Passif.soldeFermeture`
                    },
                    {
                        displayName: 'Balance Rev. Actif',
                        field: `operation.BalancesRevendeur__Actif.soldeFermeture`
                    },
                    {
                        displayName: 'Compte bancaire',
                        field: `operation.ComptesBancaire__Actif.soldeFermeture`
                    },
                    {
                        displayName: 'Comptes parallèle',
                        field: `operation.ComptesParallele__Actif.soldeFermeture`
                    },
                    {
                        displayName: 'Cautions',
                        field: `operation.Cautions__Actif.soldeFermeture`
                    },
                    {
                        displayName: 'Limite Crédit Rev.',
                        field: `operation.AgentsRevendeur__Actif.soldeFermeture`
                    },
                    {
                        displayName: 'Limite Crédit C.C',
                        field: `operation.AgentsCashCollecteur__Actif.soldeFermeture`
                    },
                    {
                        displayName: 'Limite Crédit S.D',
                        field: `operation.AgentsSuperDealer__Actif.soldeFermeture`
                    },
                    {
                        displayName: 'Caisses Napp',
                        field: `operation.Caisses__Actif.soldeFermeture`
                    },
                    {
                        displayName: 'Caisses S. Dealer',
                        field: `operation.CaissesSuperDealer__Actif.soldeFermeture`
                    },
                    {
                        displayName: 'Caisses Rev.',
                        field: `operation.CaissesRevendeur__Actif.soldeFermeture`
                    },
                    {
                        displayName: 'Caisses Cash Col.',
                        field: `operation.CaissesCashCollecteur__Actif.soldeFermeture`
                    }
                ]}
                isAddTotalTr
                payload={{
                    typeEntite: 'Journalier',
                    typeProprietaire: 'SuperDealer',
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    rapport: {
                        dateDebut: TIME.add(TODAY.at000000(), { days: -15 }),
                        dateFin: TIME.utc235959(TODAY.at235959())
                    }
                }}
                defaultPayloadMapperValues={{
                    typeEntite: 'Journalier',
                    typeProprietaire: 'SuperDealer',
                    estListReturn: true
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    }
                ]}
                chartDataListFormater={chartDataListFormater}
                chartTitle="Dashboard Napp"
                yAxisChartTitle="Fermerture ($)"
                isDisplayChart
            />
        </>
    );
}
