import { AiOutlineFundView } from 'react-icons/ai';
import { BsCashCoin, BsFillCursorFill } from 'react-icons/bs';
import { FaBalanceScale } from 'react-icons/fa';
import { GrDocumentPerformance } from 'react-icons/gr';
// import { HiBanknotes } from 'react-icons/hi2';
import {
    MdLabelOutline,
    MdLeaderboard,
    MdMobileScreenShare,
    MdPhonelinkSetup,
    MdProductionQuantityLimits
} from 'react-icons/md';

export const InstitutionFinanciereRegroupement = {
    name: 'Institution Financiere',
    icon: <AiOutlineFundView className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const StockRegroupement = {
    name: 'Stock',
    icon: <AiOutlineFundView className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const BudgetRegroupement = {
    name: 'Budget',
    icon: <AiOutlineFundView className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const Caisse = {
    name: 'Caisses',
    icon: <AiOutlineFundView className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const KinDistribution = {
    name: 'Kin distribution',
    icon: <MdLabelOutline className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const Revendeur = {
    name: 'Revendeur',
    icon: <MdProductionQuantityLimits className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const CashCollecteur = {
    name: 'Cash collecteur',
    icon: <BsCashCoin className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const TransfertCaisse = {
    name: 'Transferts',
    icon: <BsFillCursorFill className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const ServiceClient = {
    name: 'Service Client',
    icon: <MdProductionQuantityLimits className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const ApplicationMobile = {
    name: 'App Mobile',
    icon: <MdPhonelinkSetup className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const RapportGeneral = {
    name: `Rapports d'évolution`,
    icon: <MdLeaderboard className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const VerificationSolde = {
    name: 'Vérification Solde',
    icon: <MdLeaderboard className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const GainPerte = {
    name: 'Gain & Perte',
    icon: <MdLeaderboard className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const ReportRegularisation = {
    name: 'Rapport Regularisation',
    icon: <FaBalanceScale className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const MonnaieLocalMenu = {
    name: 'Monnaie Locale',
    icon: <AiOutlineFundView className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const SenderEmoney = {
    name: 'Sender E-Money',
    icon: <MdMobileScreenShare className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};

export const BasePerformance = {
    name: 'Base Performance',
    icon: <GrDocumentPerformance className="icon-custom" />,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    isDropDownMenu: true
};
