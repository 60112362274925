export const schema = [
    // {
    //     title: 'Code',
    //     description: 'code',
    //     className: 'border-bottom'
    // },
    {
        title: 'Designation',
        description: 'designation',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat',
        className: 'border-bottom'
    },
    {
        description: 'dateCreation',
        title: 'Création'
    }
];
