import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { formInitialState, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { CreateFonctionForm } from './creation-form';

const defaultFields = {
    selectedType: {},
    selectedTypeContrat: {},
    selectedProfil: {},
    selectedFonction: {},
    code: '',
    designation: '',
    listCharge: [],
    selectedCharge: {},
    montant: 0,
    niveau: 0,
    selectedAcquis: {}
};

const setDefaultProperties = ({ type }) => {
    const defaultDependencies = {
        fields: ['charges', 'profilsUserOrganisation']
    };
    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            fields: defaultFields,
            dependencies: defaultDependencies,
            elements: [],
            uiValidator: yup.object().shape({
                selectedType: yup.object().shape({
                    code: yup.string(),
                    designation: yup.string()
                }),
                selectedAcquis: yup.object().shape({
                    code: yup.string()
                }),
                selectedFonction: yup.object().shape({
                    code: yup.string(),
                    designation: yup.string()
                }),
                selectedProfil: yup.object().when('selectedType', {
                    is: (selectedType) => selectedType?.code === 'exec',
                    then: yup.object().shape({
                        code: yup.string().required(),
                        designation: yup.string().required()
                    }),
                    otherwise: yup.object().shape({
                        code: yup.string(),
                        designation: yup.string()
                    })
                }),
                code: yup.string().when('selectedType', {
                    is: (selectedType) => selectedType?.code === 'non_exec',
                    then: yup.string(),
                    otherwise: yup.string()
                }),
                designation: yup.string().when('selectedType', {
                    is: (selectedType) => selectedType?.code === 'non_exec',
                    then: yup.string().required(),
                    otherwise: yup.string()
                }),
                listCharge: yup
                    .array()
                    .of(
                        yup
                            .object({
                                code: yup.string().required(),
                                designation: yup.string().required(),
                                contrat: yup.object().shape({
                                    code: yup.string().required(),
                                    designation: yup.string().required()
                                }),
                                codeMixed: yup.string().required(),
                                montant: yup.number(),
                                niveau: yup.number(),
                                estAcquis: yup.boolean()
                            })
                            .shape({})
                    )
                    .required()
                    .min(1, 'Ajoutez au moins un élément à la liste'),
                selectedCharge: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                selectedTypeContrat: yup.object().shape({
                    code: yup.string().required(),
                    designation: yup.string().required()
                }),
                montant: yup.number(),
                niveau: yup.number()
            })
        };
    }
    return {
        fields: {},
        dependencies: { fields: [] },
        elements: [],
        uiValidator: yup.object().shape({})
    };
};

export function CreationFonctionForm({ isUpdate = false }) {
    const { firebaseUser, nappUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser
    }));
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser
    });

    const { fields, dependencies, elements, uiValidator } = typeEntite
        ? setDefaultProperties({
              type: typeEntite
          })
        : {};

    /**
     * initialState
     */
    const initialState = formInitialState({
        fields,
        dependencies,
        elements
    });

    const renderContent = () => {
        if (!isUpdate && typeEntite === TYPE_ORGANISATION.NAPP) {
            return (
                <CreateFonctionForm
                    idToken={idToken}
                    typeEntite={typeEntite}
                    uiValidator={uiValidator}
                    initialState={initialState}
                />
            );
        }
        return null;
    };

    return renderContent();
}
