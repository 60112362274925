import { useCallback } from 'react';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import { DB_NODE, FORM_CONST, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_NODE_NAME } from '../../../../redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../../components';
import { URL_CONST } from '../../../../util';

export function OperationBancaireForm({
    idToken,
    initialState,
    uiValidator,
    cfPayload,
    cfCondition,
    typeEntite
}) {
    /**
     * @desc: Redux Dispatcher
     */
    const reduxDispatcher = useDispatch();
    /**
     * @desc: Get data on redux store
     */
    const {
        caisses,
        etatCaisses,
        comptes,
        etatCompte,
        institutionsFinanciere,
        etatInstitutionsFinanciere,
        superDealerKinDB,
        etatSuperDealerKinDB
    } = useSelector((reduxState) => ({
        caisses:
            reduxState?.[cfPayload.caisseReduxProperty]?.map((item) => ({
                ...item,
                designation: `${item?.designation || ''}-${item?.devise || ''}`
            })) || [],
        etatCaisses: reduxState?.etat?.[cfPayload.caisseReduxProperty]?.etat,
        comptes: reduxState.comptes,
        etatCompte: reduxState.etat.comptes.etat,
        institutionsFinanciere: reduxState.institutionsFinanciere,
        etatInstitutionsFinanciere: reduxState.etat.institutionsFinanciere.etat,
        superDealerKinDB: reduxState.allSuperDealerKinDB.map((item) => ({
            ...item,
            code: item.entreprise.code,
            designation: item.entreprise.designation
        })),
        etatSuperDealerKinDB: reduxState.etat.allSuperDealerKinDB.etat
    }));

    /**
     * @desc: form state, form dispatcher
     */
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    /**
     * @desc: FIELDS, FORM, ELEMENTS, DEPENDECIES
     */
    const { fields, form } = formState;

    /**
     * @desc formater
     */
    const formater = (tableau = []) =>
        tableau.map((item) => ({
            ...item,
            code: item.id,
            designation: `${item.institutionFinanciere?.designation} - ${item.numero} - ${item.devise}`
        }));

    /**
     * Recupération des données nécessaires
     */
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_BANQUE,
                nodeName: DB_NODE.INSTITUTION_FINANCIERE,
                etat: etatInstitutionsFinanciere
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                nodeName: cfPayload?.caisseNodeName,
                payload: cfPayload?.caisses,
                etat: etatCaisses,
                isCondition: !!cfPayload.caisseNodeName
            },
            {
                isOnlyFetch: true,
                nodeName: DB_NODE.COMPTE,
                functionName: URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION,
                etat: etatCompte,
                formater
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB,
                etat: etatSuperDealerKinDB,
                payload: cfPayload?.superDealer,
                isCondition: !!cfCondition?.isAll
            }
        ]
    });

    const getCaisseDetail = useCallback(() => {
        const findCaisse = (caisses || []).find(
            (itemCaisse) => itemCaisse?.id === fields?.selectedCaisse?.id
        );

        return findCaisse || {};
    }, [caisses, fields?.selectedCaisse?.id]);

    const caisseDetail = getCaisseDetail();

    /**
     * @desc: Submit Function
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            newOperationBancaire: {
                typeProprietaire: fields.selectedOperation.code,
                caisse: {
                    reference: fields.selectedCaisse.id
                },
                compte: {
                    reference: fields.selectedCompte.id
                },
                montant: fields.montant
            }
        };
        if (
            fields.numero &&
            !!caisseDetail?.estEmoney &&
            fields.selectedOperation.code === 'Depot'
        ) {
            payload.newOperationBancaire.numero = fields.numero;
        }
        if (
            !fields.numero &&
            !!caisseDetail?.estEmoney &&
            fields.selectedOperation.code === 'Depot'
        ) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Veuillez Renseigner le numéro du bénéficiaire',
                    status: FORM_CONST.CREATION_ECHEC
                }
            });
            return;
        }

        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_OPERATION_BANCAIRE,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.OPERATION_BANCAIRE,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const getCompteByBanque = (cpt, bq) =>
        cpt
            .filter(
                (element) => element?.institutionFinanciere?.code === bq?.code
            )
            ?.map((item) => ({
                ...item,
                designation: `${item?.numero}-${item?.devise}`
            }));

    // const filterCaisseByComtpeDevise = (
    //     uComptes = comptes,
    //     uComptesId = fields.selectedCompte?.id,
    //     uCaisses = caisses
    // ) =>
    //     uCaisses.filter(
    //         (caisse) =>
    //             caisse?.devise ===
    //             uComptes.find((compte) => compte.id === uComptesId)?.devise
    //     );

    const filterCaisseByComtpeDevise = useCallback(
        (
            uComptes = comptes,
            uComptesId = fields.selectedCompte?.id,
            uCaisses = caisses
        ) =>
            uCaisses.filter(
                (caisse) =>
                    caisse?.devise ===
                    uComptes.find((compte) => compte.id === uComptesId)?.devise
            ),
        [comptes, fields.selectedCompte.id, caisses]
    );
    // const filterCaisseByComtpeDeviseAndCodeSuperDealer = ({
    //     uComptes = comptes,
    //     uComptesId = fields.selectedCompte?.id,
    //     uCaisses = caisses
    // }) => {
    //     const result = uCaisses.filter(
    //         (caisse) =>
    //             caisse?.devise ===
    //             uComptes.find((compte) => compte.id === uComptesId)?.devise
    //     );
    //     if (fields.selectedSuperDealer.code === 'AUTRES') {
    //         return result.filter(
    //             (item) => item?.typeEntite !== TYPE_ORGANISATION.SUPER_DEALER
    //         );
    //     }
    //     if (
    //         fields.selectedSuperDealer.code &&
    //         fields.selectedSuperDealer.code !== 'AUTRES'
    //     ) {
    //         return result.filter(
    //             (item) =>
    //                 item?.superDealer?.code === fields.selectedSuperDealer.code
    //         );
    //     }
    //     return result;
    // };
    const filterCaisses = useCallback(
        ({
            uComptes = comptes,
            uComptesId = fields.selectedCompte?.id,
            uCaisses = caisses
        }) => {
            const result = uCaisses.filter(
                (caisse) =>
                    caisse?.devise ===
                    uComptes.find((compte) => compte.id === uComptesId)?.devise
            );
            if (fields.selectedSuperDealer.code === 'AUTRES') {
                return result.filter(
                    (item) =>
                        item?.typeEntite !== TYPE_ORGANISATION.SUPER_DEALER
                );
            }
            if (
                fields.selectedSuperDealer.code &&
                fields.selectedSuperDealer.code !== 'AUTRES'
            ) {
                return result.filter(
                    (item) =>
                        item?.superDealer?.code ===
                        fields.selectedSuperDealer.code
                );
            }
            return result;
        },
        [
            fields.selectedSuperDealer.code,
            fields.selectedCompte.id,
            comptes,
            caisses
        ]
    );

    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'banques',
                    etat: etatInstitutionsFinanciere
                },
                {
                    dependency: 'caisses',
                    etat: etatCaisses,
                    isCondition: !!cfPayload.caisseNodeName
                },
                {
                    dependency: 'comptes',
                    etat: etatCompte
                },
                {
                    dependency: 'superDealer',
                    etat: etatSuperDealerKinDB,
                    isCondition: !!cfCondition?.isAll
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    idProperty="code"
                    propertyToDisplay="designation"
                    formDispatcher={formDispatcher}
                    formState={formState}
                    uiValidator={uiValidator}
                    label="Institution Financière* : "
                    name="selectedInstitutionFinanciere"
                    id="creationOperationBanciareId"
                    options={institutionsFinanciere}
                    selectionClassName="form-select-md"
                    defaultOption="Sélectionner"
                    divClassName="col-sm-10"
                    labelClassName="col-sm-2"
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={getCompteByBanque(
                        comptes,
                        fields.selectedInstitutionFinanciere
                    )}
                    label="Compte* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    defaultOption="Sélectionner Compte"
                    name="selectedCompte"
                    id="selectedCompteCreationDepot"
                    formState={formState}
                    idProperty="id"
                    uiValidator={uiValidator}
                    disabled={
                        !getCompteByBanque(
                            comptes,
                            fields.selectedInstitutionFinanciere
                        )?.length
                    }
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={typeEntite === TYPE_ORGANISATION.NAPP}
            >
                <CustomDropdown
                    isSort={false}
                    options={[
                        { code: 'AUTRES', designation: 'AUTRES' },
                        ...superDealerKinDB
                    ]}
                    defaultOption="Organisation Responsable de la caisse"
                    label="Organisation: "
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedSuperDealer"
                    id="selectedSuperDealerCreationOperationBancaireId"
                    // uiValidator={CreationApproCompteEmoneySchema}
                    formState={formState}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={
                        typeEntite === TYPE_ORGANISATION.NAPP
                            ? filterCaisses({
                                  uComptes: comptes,
                                  uComptesId: fields.selectedCompte?.id,
                                  uCaisses: caisses
                              })
                            : filterCaisseByComtpeDevise(
                                  comptes,
                                  fields.selectedCompte?.id,
                                  caisses
                              )
                    }
                    label="Caisse* :"
                    defaultOption="Sélectionner caisse"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    name="selectedCaisse"
                    id="creationDepotSelectedCaisseId"
                    formState={formState}
                    idProperty="id"
                    uiValidator={uiValidator}
                    disabled={
                        typeEntite === TYPE_ORGANISATION.NAPP
                            ? !filterCaisses({
                                  uComptes: comptes,
                                  uComptesId: fields.selectedCompte?.id,
                                  uCaisses: caisses
                              })?.length
                            : !filterCaisseByComtpeDevise(
                                  comptes,
                                  fields.selectedCompte?.id,
                                  caisses
                              )?.length
                    }
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    options={[{ code: 'Depot' }, { code: 'Retrait' }]}
                    label="Opération* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    formDispatcher={formDispatcher}
                    defaultOption="Sélectionner Opération"
                    name="selectedOperation"
                    id="selectedOperationCreationDepot"
                    formState={formState}
                    idProperty="code"
                    propertyToDisplay="code"
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    !!caisseDetail?.estEmoney &&
                    fields.selectedOperation.code === 'Depot'
                }
            >
                <CustomInput
                    label="Numéro* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Numéro Bénéficiaire"
                    formDispatcher={formDispatcher}
                    name="numero"
                    id="creationDepotNumeroId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomInput
                    type="number"
                    label="Montant* :"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    isInputGroup={false}
                    placeholder="Montant"
                    formDispatcher={formDispatcher}
                    name="montant"
                    id="creationDepotMontantId"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
