import { BiCubeAlt } from 'react-icons/bi';
import {
    AiOutlineFundView,
    AiOutlineLink,
    AiOutlineMergeCells,
    AiOutlineRightCircle,
    AiOutlineUsergroupAdd
} from 'react-icons/ai';
import {
    MdCancelScheduleSend,
    MdErrorOutline,
    MdLeaderboard,
    MdExpand,
    MdPending,
    MdSource,
    MdSettingsAccessibility,
    MdVerticalSplit,
    MdAvTimer,
    MdAutoGraph,
    MdTransferWithinAStation,
    MdDynamicFeed,
    MdAdsClick
} from 'react-icons/md';
import { BsJournalBookmark, BsWallet } from 'react-icons/bs';
import { HiOutlineCreditCard } from 'react-icons/hi';
import { GiMoneyStack } from 'react-icons/gi';
import { IoIosWallet } from 'react-icons/io';

export const DetailMouvementRelPath = {
    name: '',
    path: ':id'
};

export const ListMouvementRelPath = {
    name: 'Mouvements',
    path: 'mouvements',
    icon: <BiCubeAlt className="icon-custom" />
};

export const DetailMouvementOperationRelPath = {
    name: '',
    path: ':id'
};

export const ListMouvementOperationRelPath = {
    name: 'Mouvements  opération',
    path: 'mouvements-op',
    icon: <BiCubeAlt className="icon-custom" />
};
export const ReleveCompteViewLayoutRelPath = {
    name: 'Rapport  compte',
    path: 'rlv-compte',
    icon: <BsJournalBookmark className="icon-custom" />
};
export const ReleveEmoneyViewLayoutRelPath = {
    name: 'Rapport Importation',
    path: 'rlv-emoney',
    icon: <BsJournalBookmark className="icon-custom" />
};

export const RapportComptabilisationCompteOrganisationViewLayoutRelPath = {
    name: 'Rapport Comptabilisation Compte',
    path: 'rp-comptabilisation-cmpt',
    icon: <BsJournalBookmark className="icon-custom" />
};

export const ReleveCaisseViewLayoutRelPath = {
    name: 'Rapport caisse',
    path: 'rlv-caisse',
    icon: <BiCubeAlt className="icon-custom" />
};

export const ReleveCaisseEmoneyViewLayoutRelPath = {
    name: 'Caisses',
    path: 'rpt-caisse-emoney',
    icon: <BiCubeAlt className="icon-custom" />
};

// FIXME: TO REVIEW
export const CommandeFlashRelPath = {
    name: 'Rapport Airtime',
    path: 'rp-cmd-flash',
    icon: <AiOutlineFundView className="icon-custom" />
};

/**
 * @desc Cas litigieux rel paths
 */
export const CasLitigieuxViewLayoutRelPath = {
    name: 'Commande litgieuse',
    path: 'cas-litigieux',
    icon: <MdErrorOutline className="icon-custom" />
};

export const CasLitigieuxDetailRelPath = {
    name: '',
    path: ':id'
};

export const RapportOperationBancaireViewLayoutRelPath = {
    name: 'Mes banques',
    path: 'mybanks',
    icon: <BsJournalBookmark className="icon-custom" />
};

/**
 * @desc Rapport Trop perçu rel paths
 */
export const RapportTropPercuViewLayoutRelPath = {
    name: 'Trop perçu',
    path: 'trop-percu',
    icon: <MdExpand className="icon-custom" />
};
/**
 * @desc Cas litigieux Emoney rel paths
 */
export const CasLitigieuxEmoneyViewLayoutRelPath = {
    name: 'Litigieuse emoney',
    path: 'litigieux-emoney',
    icon: <MdCancelScheduleSend className="icon-custom" />
};

export const CasLitigieuxEmoneyDetailRelPath = {
    name: '',
    path: ':id'
};

/**
 * @desc Rapport commande Emoney rel paths
 */
export const RapportCommandeEmoneyViewLayoutRelPath = {
    name: 'Rapport emoney',
    path: 'rp-cmd-emoney',
    icon: <MdLeaderboard className="icon-custom" />
};

/**
 * @desc Rapport commande Emoney rel paths
 */
export const RapportPresenceAgentViewLayoutRelPath = {
    name: 'Rapport Présence',
    path: 'rp-pres-agent',
    icon: <MdLeaderboard className="icon-custom" />
};

/**
 * @desc Rapport activite rel paths
 */
export const RapportActiviteViewLayoutRelPath = {
    name: 'Rapport activite',
    path: 'rapport-activite',
    icon: <HiOutlineCreditCard className="icon-custom" />
};

/**
 * @desc Fonds de roulement kin db rel Path
 */
export const FondsRoulementKinDBRelPath = {
    name: 'Business Evolution',
    path: 'fonds-roulement-kindb',
    icon: <MdAvTimer className="icon-custom" />
};

/**
 * @desc Rapport stock kin db rel Path
 */
export const RapportStockKinDBRelPath = {
    name: 'Stock Airtime',
    path: 'stock-kindb',
    icon: <MdSource className="icon-custom" />
};

/**
 * @desc Rapport stock kin db rel Path
 */
export const RapportStockSimKinDBRelPath = {
    name: 'Stock Sim',
    path: 'stock-sim-kindb',
    icon: <MdSource className="icon-custom" />
};

/**
 * @desc Rapport caisse kin db rel Path
 */
export const RapportCaisseKinDBRelPath = {
    name: 'Caisse',
    path: 'caisse-kindb',
    icon: <MdVerticalSplit className="icon-custom" />
};

/**
 * @desc Rapport Institution financiere kin db rel Path
 */
export const RapportInstitutionFinanciereKinDBRelPath = {
    name: 'Institution financière',
    path: 'inst-fin-kindb',
    icon: <MdAutoGraph className="icon-custom" />
};

/**
 * @desc Rapport Balance kin db rel Path
 */
export const RapportBalanceKinDBRelPath = {
    name: 'Balance',
    path: 'balance-kindb',
    icon: <MdPending className="icon-custom" />
};

/**
 * @desc Rapport Agent kin db rel Path
 */
export const RapportAgentKinDBRelPath = {
    name: 'Limite de crédit',
    path: 'agent-kindb',
    icon: <MdTransferWithinAStation className="icon-custom" />
};

/**
 * @desc Rapport Partenaire kin db rel Path
 */
export const RapportPartenaireKinDBRelPath = {
    name: 'Partenaire',
    path: 'partenaire-kindb',
    icon: <MdSettingsAccessibility className="icon-custom" />
};

/**
 * @desc Rapport liaison kin db rel Path
 */
export const RapportLiaisonKinDBRelPath = {
    name: 'Liaison',
    path: 'liaison-kindb',
    icon: <AiOutlineLink className="icon-custom" />
};

/**
 * @desc Rapport caution kin db rel Path
 */
export const RapportCautionKinDBRelPath = {
    name: 'Caution',
    path: 'caution-kindb',
    icon: <AiOutlineMergeCells className="icon-custom" />
};

/**
 * @desc Rapport retrait non déclaré kin db rel Path
 */
export const RapportRetraitNonDeclareKinDBRelPath = {
    name: 'Retrait',
    path: 'retrait-non-declare',
    icon: <AiOutlineRightCircle className="icon-custom" />
};
/**
 * Rapport Cash collecteur
 */

/**
 * @desc Fonds de roulement cash collecteur rel Path
 */
export const FondsRoulementCashCollecteurRelPath = {
    name: 'Fonds de Roulement',
    path: 'fonds-roulement-cc',
    icon: <MdAvTimer className="icon-custom" />
};

/**
 * @desc Rapport Partenaire cash collecteur rel Path
 */
export const RapportPartenaireCashCollecteurRelPath = {
    name: 'Partenaire',
    path: 'partenaire-cc',
    icon: <MdSettingsAccessibility className="icon-custom" />
};

/**
 * @desc Rapport Caisse cash collecteur rel Path
 */
export const RapportCaisseCashCollecteurRelPath = {
    name: 'Caisse',
    path: 'caisse-cc',
    icon: <MdVerticalSplit className="icon-custom" />
};

/**
 * @desc Rapport Agent cash collecteur rel Path
 */
export const RapportAgentCashCollecteurRelPath = {
    name: 'Limite de crédit',
    path: 'agent-cc',
    icon: <MdTransferWithinAStation className="icon-custom" />
};

/**
 * @desc Rapport Institution financiere kin db rel Path
 */
export const RapportInstitutionFinanciereCashCollecteurRelPath = {
    name: 'Institution financière',
    path: 'inst-fin-cc',
    icon: <MdAutoGraph className="icon-custom" />
};

/**
 * @desc Rapport Balance cash collecteur rel Path
 */
export const RapportBalanceCashCollecteurRelPath = {
    name: 'Balance',
    path: 'balance-cc',
    icon: <MdPending className="icon-custom" />
};

/**
 * @desc Rapport Liaison cash collecteur rel Path
 */
export const RapportLiaisonCashCollecteurRelPath = {
    name: 'Liaison',
    path: 'liaison-cc',
    icon: <AiOutlineLink className="icon-custom" />
};
/**
 * Rapport revendeur
 */

/**
 * @desc Balance Revendeur rel Path
 */
export const RapportBalanceRevendeurRelPath = {
    name: 'Balance',
    path: 'balance-revendeur',
    icon: <MdPending className="icon-custom" />
};

/**
 * @desc Vente Revendeur rel Path
 */
export const VenteRevendeurRelPath = {
    name: 'Rapport de Vente',
    path: 'vente-revendeur',
    icon: <MdDynamicFeed className="icon-custom" />
};

/**
 * @desc Rapport Agent revendeur rel Path
 */
export const RapportAgentRevendeurRelPath = {
    name: 'Limite de crédit',
    path: 'agent-revendeur',
    icon: <MdTransferWithinAStation className="icon-custom" />
};

/**
 * @desc Rapport caisse Revendeur rel Path
 */
export const RapportCaisseRevendeurRelPath = {
    name: 'Caisse',
    path: 'caisse-revendeur',
    icon: <MdVerticalSplit className="icon-custom" />
};

/**
 * @desc Rapport Commission Revendeur rel Path
 */
export const RapportCommissionRevendeurRelPath = {
    name: 'Commission',
    path: 'commission-revendeur',
    icon: <MdAdsClick className="icon-custom" />
};

/**
 * @desc Rapport Recruteur Revendeur rel Path
 */
export const RapportRecruteurRevendeurRelPath = {
    name: 'Recruteur',
    path: 'recruteur-revendeur',
    icon: <AiOutlineUsergroupAdd className="icon-custom" />
};

/**
 * @desc Rapport Liaison Revendeur rel Path
 */
export const RapportLiaisonRevendeurRelPath = {
    name: 'Liaison',
    path: 'liaison-revendeur',
    icon: <AiOutlineLink className="icon-custom" />
};

/**
 * @desc Rapport Caisse Emoney Organisation rel paths
 */
export const RapportCaisseEmoneyOrganisationViewLayoutRelPath = {
    name: 'Evolution Caisse Emoney',
    path: 'rp-caisser-emoney',
    icon: <MdLeaderboard className="icon-custom" />
};

/**
 * @desc Rapport Evolution des Balances Organisation
 */
export const RapportEvolutionStockOrganisationViewLayoutRelPath = {
    name: 'Evolution Stock',
    path: 'rp-evol-stock',
    icon: <AiOutlineLink className="icon-custom" />
};

/**
 * @desc Rapport Evolution des Balances Organisation
 */
export const RapportEvolutionBalanceOrganisationRelPath = {
    name: 'Evolution Balance',
    path: 'rp-evol-bal',
    icon: <AiOutlineLink className="icon-custom" />
};

/**
 * @desc Rapport Gain et perte vente
 */
export const RapportGainPerteVenteRelPath = {
    name: 'Vente',
    path: 'rp-gp-vente',
    icon: <AiOutlineLink className="icon-custom" />
};

/**
 * @desc Rapport Gain et perte change
 */
export const RapportGainPerteChangeRelPath = {
    name: 'Revenu de Change',
    path: 'rp-gp-change',
    icon: <AiOutlineLink className="icon-custom" />
};

/**
 * @desc Rapport Regularisation Produit
 */
export const RapportRegularisationProduitRelPath = {
    name: 'Produit',
    path: 'rp-regul-produit',
    icon: <IoIosWallet className="icon-custom" />
};

/**
 * @desc Rapport Regularisation Caisse
 */
export const RapportRegularisationCaisseRelPath = {
    name: 'Caisse',
    path: 'rp-regul-caisse',
    icon: <GiMoneyStack className="icon-custom" />
};

/**
 * @desc Rapport Regularisation Stock
 */
export const RapportRegularisationStockRelPath = {
    name: 'Stock',
    path: 'rp-regul-stock',
    icon: <IoIosWallet className="icon-custom" />
};

/**
 * @desc Rapport Comptabilisation Kin db rel Path
 */
export const RapportComptabilisationKinDBRelPath = {
    name: 'Comptabilisation',
    path: 'comptabilisation-kindb',
    icon: <MdSource className="icon-custom" />
};

/**
 * @desc Rapport Comptabilisation Kin db rel Path
 */
export const BasePerformanceKinDBRelPath = {
    name: 'Dépôt Base',
    path: 'base-performance-depot',
    icon: <BsWallet className="icon-custom" />
};
