import {
    /** submitPutAction, */ useFetchAndListening
} from '@napp-inc/jnapp-hook';
import {
    CLASSE_CHARGE_FIXE,
    DB_NODE,
    FORM_CONST,
    GENERIC_ACTIONS_TYPE
} from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import {
    CenteredSpinner,
    CustomAlert,
    CustomConfirmButton,
    CustomDropdown,
    CustomRow,
    DetailWrapper,
    UserServiceWrapper
} from '../../../components';
import { useGetDetailForm, URL_CONST } from '../../../util';
import { schema } from './detail-fields';

const defaultFields = {
    actionEncours: '',
    selectedDevise: {}
};

const defaultDependencies = { entity: true, fields: ['devises'] };

export function DetailChargeForm() {
    const { devises, etatDevise } = useSelector((reduxState) => ({
        devises: reduxState.devises.map((item) => ({
            ...item,
            code: item?.devise || item?.code,
            designation: item?.devise || item?.designation
        })),
        etatDevise: reduxState.etat.devises.etat
    }));
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({
            nodeName: 'charges',
            defaultFields,
            defaultDependencies
        });

    const { fields, form } = formState;

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true /** @explication : Récupération de la monnaie pour lier chaque charge à une devise bien précise */,
                functionName: URL_CONST.GET_ALL_DEVISE,
                nodeName: DB_NODE.DEVISE,
                etat: etatDevise,
                isCondition: entity?.classe === CLASSE_CHARGE_FIXE.code
            }
        ]
    });
    const updateSubmit = () => {
        if (!entity.id || !fields.selectedDevise.code) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `Vérifiez les informations du formulaire`,
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
            return;
        }

        const payload = {
            idCharge: entity.id,
            devise: fields.selectedDevise.code
        };

        if (payload.idCharge || payload.devise) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: `UI_ERROR : Function not available`,
                    status: FORM_CONST.MODIFICATION_ECHEC
                }
            });
        }

        // formDispatcher(
        //     submitPutAction({
        //         idToken,
        //         fields,
        //         functionName: URL_CONST.PUT_CHARGE,
        //         payload
        //         // uiValidator
        //     })
        // );
    };
    const deviseToRender = () => {
        if (etatDevise === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS) {
            return (
                <UserServiceWrapper
                    services={[
                        { code: 'test' }
                        // CODE_SERVICE.SERVICE_CODE_WEB.ORGANISATION_STRUCTURE
                        //     .DEFINIR_LOGO_ORGANISATION
                    ]}
                >
                    {entity?.classe === CLASSE_CHARGE_FIXE.code ? (
                        <div className="card p-2">
                            <h5 className="h5 mb-3 fw-bolder text-uppercase">
                                Définir la devise de la charge
                            </h5>
                            <CustomRow
                                isShouldBeRendered={
                                    entity?.classe === CLASSE_CHARGE_FIXE.code
                                }
                            >
                                <CustomDropdown
                                    options={devises}
                                    label="Devise*"
                                    labelClassName="col-12 col-sm-2"
                                    divClassName="col-12 col-sm-10"
                                    name="selectedDevise"
                                    id="selectedDeviseCreationCharge"
                                    defaultOption="Selectionnez la dévise"
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    // uiValidator={uiValidator}
                                />
                            </CustomRow>
                            <CustomRow
                                className="row"
                                isShouldBeRendered={
                                    entity?.classe === CLASSE_CHARGE_FIXE.code
                                }
                            >
                                <CustomConfirmButton
                                    type="button"
                                    backdrop="static"
                                    text="Mettre à jour"
                                    className="btn-success btn ms-3 mt-3 w-25"
                                    confirmOnClick={updateSubmit}
                                    disabled={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                    isActionEncours={
                                        form.status ===
                                        FORM_CONST.MODIFICATION_EN_COURS
                                    }
                                />
                            </CustomRow>
                        </div>
                    ) : null}
                </UserServiceWrapper>
            );
        }
        if (etatDevise === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des comtpes" />
            );
        }
        return <CenteredSpinner />;
    };
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_CHARGE_BY_ID}
            nodeName={DB_NODE.CHARGE}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Charge Fixe"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
            listDependencies={[
                {
                    dependency: 'devises',
                    etat: etatDevise,
                    isCondition: entity?.classe === CLASSE_CHARGE_FIXE.code
                }
            ]}
        >
            {deviseToRender()}
        </DetailWrapper>
    );
    return renderContent();
}
