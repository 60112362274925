import {
    submitPostAction,
    submitPutAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    FORM_CONST,
    CLASSE_CHARGE_FIXE,
    CLASSE_CHARGE_VARIABLE,
    LIST_TYPE_CHARGE_VARIABLE,
    TYPE_IMPUTATION_CHARGE,
    TYPE_CHARGE_VARIABLE_RETRIBUTION,
    LIST_TYPE_ENTITE_CHARGE_VARIABLE,
    TYPE_CHARGE_VARIABLE_COMMISSION // Value to remove
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButton,
    CustomConfirmButton,
    CustomDropdown,
    CustomInput,
    CustomLabel,
    CustomRadioButton,
    CustomRow,
    CustomSwitchButton,
    FormWrapper,
    SimpleTable
} from '../../../components';
import { formatCodeDesignation, URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';
import { ChargeSchema as uiValidator } from './validation';

const defaultFields = {
    selectedClasse: CLASSE_CHARGE_FIXE.code,
    selectedTypeChargeVariable: {},
    code: '',
    designation: '',
    selectedCompteImputation: {},
    selectedServiceGenerateur: {},
    selectedDevise: {},
    typeOperation: 'modification', // This Shall be removed
    estSous: false,
    estQuantite: false,
    listCharges: [],
    listChargesAModifier: [], // This Shall be removed
    selectedCharge: {}, // This Shall be removed
    selectedTypeEntite: {} // This Shall be removed
};

// FIXME: ADDING LOGIC THAT SHOULD BE REMOVED AND THE UIVALIDATOR
export function CreationChargeForm() {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        comptesImputation,
        etatCompteImputation,
        servicesGenerateur,
        etatServiceGenerateur,
        devises,
        etatDevise,
        charges /** This Shall be removed */,
        etatCharges /** This Shall be removed */
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        comptesImputation: reduxState.comptesImputation
            .filter((item) => item.typeProprietaire === TYPE_IMPUTATION_CHARGE)
            .map((item) => ({
                ...item,
                ...item.valeurTypeProprietaire
            })),
        etatCompteImputation: reduxState.etat.comptesImputation.etat,
        servicesGenerateur: reduxState.servicesGenerateur,
        etatServiceGenerateur: reduxState.etat.servicesGenerateur.etat,
        devises: reduxState.devises.map((item) => ({
            ...item,
            code: item?.devise || item?.code,
            designation: item?.devise || item?.designation
        })),
        etatDevise: reduxState.etat.devises.etat,
        charges: reduxState.charges.filter(
            (item) =>
                item.classe === CLASSE_CHARGE_FIXE.code &&
                !item.devise &&
                !item.estRempliAutomatique
        ) /** This Shall be removed */,
        etatCharges: reduxState.etat.charges.etat /** This Shall be removed */
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: {
            fields: ['compteImputation', 'serviceGenerateur', 'devises']
        }
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_COMPTE_IMPUTATION,
                nodeName: REDUX_NODE_NAME.COMPTE_IMPUTATION,
                etat: etatCompteImputation
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SERVICE_GENERATEUR,
                nodeName: DB_NODE.SERVICE_GENERATEUR,
                etat: etatServiceGenerateur
            },
            {
                isOnlyFetch: true /** @explication : Récupération de la monnaie pour lier chaque charge à une devise bien précise */,
                functionName: URL_CONST.GET_ALL_DEVISE,
                nodeName: DB_NODE.DEVISE,
                etat: etatDevise
            },
            {
                isOnlyFetch: true /** @explication : This Shall be removed */,
                functionName: URL_CONST.GET_ALL_CHARGE,
                nodeName: DB_NODE.CHARGE,
                etat: etatCharges
            }
        ]
    });

    /**
     * Cette logique va revenir à celles d'avant (elle est provisoire)
     * @param {*} e
     */

    const onAddCharge = () => {
        if (fields.typeOperation === 'creation') {
            const {
                // code,
                designation,
                selectedCompteImputation,
                selectedClasse
            } = fields;
            const firstPartOfCode =
                selectedClasse === CLASSE_CHARGE_FIXE.code
                    ? 'NAPP_CHARGE_FIXE_'
                    : 'NAPP_CHARGE_VARIABLE_';

            const code = formatCodeDesignation({
                partToAdd: firstPartOfCode,
                designation: designation.trim()
            });
            const chargeFixeAdditional = {};
            if (selectedClasse === CLASSE_CHARGE_FIXE.code) {
                chargeFixeAdditional.estQuantite = fields.estQuantite;
                chargeFixeAdditional.devise = fields.selectedDevise.code;
            }
            const data = {
                code,
                newCharge: {
                    code,
                    designation: designation.trim(),
                    classe: selectedClasse,
                    compteImputation: selectedCompteImputation,
                    ...chargeFixeAdditional
                },
                creator
            };
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    identifier: 'code',
                    field: 'listCharges',
                    data
                }
            });
        }
        if (fields.typeOperation === 'modification') {
            const charge = charges.find(
                (item) => item.code === fields.selectedCharge.code
            );
            const data = {
                code: charge.code,
                id: charge.id,
                devise: fields.selectedDevise.code
            };
            formDispatcher({
                type: FORM_CONST.ADD_ITEM,
                payload: {
                    identifier: 'code',
                    field: 'listChargesAModifier',
                    data
                }
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (fields.typeOperation === 'creation') {
            const {
                designation,
                selectedCompteImputation,
                selectedClasse,
                estSous,
                selectedServiceGenerateur,
                selectedTypeChargeVariable
            } = fields;
            const chargeVaribleAdditional = {};
            const payload = {};
            // const chargeFixeAdditional = {};
            // if (selectedClasse === CLASSE_CHARGE_FIXE.code) {
            //     chargeFixeAdditional.estQuantite = fields.estQuantite;
            // }
            if (selectedClasse === CLASSE_CHARGE_VARIABLE.code) {
                const servGen = servicesGenerateur.find(
                    ({ id }) => id === selectedServiceGenerateur.id
                );
                if (servGen) {
                    chargeVaribleAdditional.serviceGenerateur = {
                        code: servGen.code,
                        type: servGen.type
                    };
                }
                chargeVaribleAdditional.estSous = estSous;
                chargeVaribleAdditional.type = selectedTypeChargeVariable.code;
                if (
                    fields.selectedTypeEntite.code &&
                    fields.selectedTypeChargeVariable.code ===
                        TYPE_CHARGE_VARIABLE_RETRIBUTION.code
                ) {
                    chargeVaribleAdditional.typeEntite =
                        fields.selectedTypeEntite.code;
                }
            }
            // const payload = {
            //     newCharge: {
            //         code,
            //         designation,
            //         classe: selectedClasse,
            //         compteImputation: selectedCompteImputation,
            //         ...chargeVaribleAdditional
            //     },
            //     creator
            // };
            if (
                selectedClasse === CLASSE_CHARGE_FIXE.code &&
                !fields.listCharges.length
            ) {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: 'Ajouter les charges fixes avant de valider',
                        status: FORM_CONST.MODIFICATION_ECHEC
                    }
                });
                return;
            }
            if (selectedClasse === CLASSE_CHARGE_FIXE.code) {
                payload.list = fields.listCharges.map(({ newCharge }) => ({
                    ...newCharge
                }));
            }
            if (selectedClasse === CLASSE_CHARGE_VARIABLE.code) {
                const firstPartOfCode = 'NAPP_CHARGE_VARIABLE_';

                const codeVariable = formatCodeDesignation({
                    partToAdd: firstPartOfCode,
                    designation: designation.trim()
                });
                payload.newCharge = {
                    code: codeVariable,
                    designation,
                    classe: selectedClasse,
                    compteImputation: selectedCompteImputation,
                    ...chargeVaribleAdditional
                };
            }
            formDispatcher(
                submitPostAction({
                    idToken,
                    functionName: URL_CONST.POST_CHARGE,
                    payload,
                    fields,
                    reduxNodeName: DB_NODE.CHARGE,
                    reduxDispatcher
                    // uiValidator
                })
            );
        }
        if (fields.typeOperation === 'modification') {
            if (!fields.listChargesAModifier.length) {
                formDispatcher({
                    type: FORM_CONST.SET_FORM_ERROR,
                    payload: {
                        message: `Renseignez les charges à modifier`,
                        status: FORM_CONST.MODIFICATION_ECHEC
                    }
                });
                return;
            }

            const list = fields.listChargesAModifier.map((item) => ({
                idCharge: item.id,
                devise: item.devise
            }));

            const payload = {
                list
            };
            formDispatcher(
                submitPutAction({
                    idToken,
                    fields,
                    functionName: URL_CONST.PUT_LIST_CHARGE,
                    payload,
                    reduxDispatcher
                    // uiValidator
                })
            );
        }
    };
    const renderContent = () => (
        <FormWrapper
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'compteImputation',
                    etat: etatCompteImputation
                },
                {
                    dependency: 'serviceGenerateur',
                    etat: etatServiceGenerateur
                },
                {
                    dependency: 'devises',
                    etat: etatDevise
                }
            ]}
        >
            {/* This CustomRow shall be removed */}
            <div className="card p-2 mb-2">
                <h5 className="h5 mb-3 fw-bolder text-uppercase">Opération</h5>
                <div className="d-flex">
                    <CustomRadioButton
                        divClassName="col-3"
                        label="Création"
                        id="typeCreationCreationChargeId"
                        name="typeOperation"
                        value="creation"
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                    <CustomRadioButton
                        divClassName="col-6"
                        label="Modifier une liste de charge existante"
                        id="typeModificationCreationChargeId"
                        name="typeOperation"
                        value="modification"
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                    />
                </div>
            </div>
            {/* This shall be removed */}

            {fields.typeOperation === 'modification' ? (
                <div className="card p-2">
                    <h5 className="h5 mb-3 fw-bolder text-uppercase">
                        Modifier
                    </h5>
                    <CustomRow>
                        <CustomDropdown
                            options={charges}
                            label="Charges*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            name="selectedCharge"
                            id="selectedChargeCreationChargeID"
                            defaultOption="Selectionnez la charge"
                            formDispatcher={formDispatcher}
                            formState={formState}
                            // uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            options={devises}
                            label="Devise*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            name="selectedDevise"
                            id="selectedDeviseCreationCharge"
                            defaultOption="Selectionnez la dévise"
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomRow className="px-3">
                        <CustomButton
                            type="button"
                            onClick={onAddCharge}
                            identifier="code"
                            className="col-sm-2 offset-sm-10"
                            text="Ajouter"
                        />
                    </CustomRow>
                    {fields.listChargesAModifier.length ? (
                        <SimpleTable
                            identifier="code"
                            formDispatcher={formDispatcher}
                            data={fields.listChargesAModifier}
                            isShowDropButton
                            listSelectedItemName="listChargesAModifier"
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    position: 1,
                                    displayName: 'Code',
                                    field: 'code'
                                },
                                {
                                    position: 2,
                                    displayName: 'Devise',
                                    field: 'devise'
                                }
                            ]}
                        />
                    ) : null}
                    <CustomConfirmButton
                        type="button"
                        backdrop="static"
                        text="Mettre à jour"
                        className="btn-success btn mt-3 w-25"
                        confirmOnClick={handleSubmit}
                        disabled={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                        isActionEncours={
                            form.status === FORM_CONST.MODIFICATION_EN_COURS
                        }
                    />
                </div>
            ) : null}
            {fields.typeOperation === 'creation' ? (
                <div className="card p-2">
                    <h5 className="h5 mb-3 fw-bolder text-uppercase">
                        Créer charge
                    </h5>
                    <CustomRow>
                        <CustomLabel
                            text="Classe*"
                            className="col-12 col-md-2"
                        />
                        <CustomRadioButton
                            divClassName="col-6 col-sm-3"
                            label="Fixe"
                            id="typeFixeCreationChargeId"
                            name="selectedClasse"
                            value={CLASSE_CHARGE_FIXE.code}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                        <CustomRadioButton
                            divClassName="col-6 col-sm-3"
                            label="Variable"
                            id="typeVariableCreationChargeId"
                            name="selectedClasse"
                            value={CLASSE_CHARGE_VARIABLE.code}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                    {/* <CustomRow>
                        <CustomInput
                            isInputGroup={false}
                            label="Code*"
                            labelClassName="col-12 col-md-2"
                            divClassName="col-12 col-md-10"
                            placeholder="Saisissez le code"
                            name="code"
                            id="codeCreationCharge"
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow> */}
                    <CustomRow>
                        <CustomInput
                            isInputGroup={false}
                            label="Designation*"
                            labelClassName="col-12 col-md-2"
                            divClassName="col-12 col-md-10"
                            placeholder="Saisissez le nom"
                            formDispatcher={formDispatcher}
                            name="designation"
                            id="designationCreationCharge"
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            options={comptesImputation}
                            label="Compte imputation*"
                            labelClassName="col-12 col-sm-2"
                            divClassName="col-12 col-sm-10"
                            name="selectedCompteImputation"
                            id="selectedCompteImputationCreationCharge"
                            defaultOption="Selectionnez imputation"
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                        />
                    </CustomRow>
                    {fields.selectedClasse === CLASSE_CHARGE_FIXE.code ? (
                        <>
                            <CustomRow>
                                <CustomDropdown
                                    options={devises}
                                    label="Devise*"
                                    labelClassName="col-12 col-sm-2"
                                    divClassName="col-12 col-sm-10"
                                    name="selectedDevise"
                                    id="selectedDeviseCreationCharge"
                                    defaultOption="Selectionnez la dévise"
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    uiValidator={uiValidator}
                                />
                            </CustomRow>
                            <CustomSwitchButton
                                id="estQuantiteCreationCharge"
                                text="Est quantité"
                                inputTitle="Est Quantité (commission, retribution ou prime)"
                                formDispatcher={formDispatcher}
                                name="estQuantite"
                                divClassName="col-md-8"
                                formState={formState}
                                uiValidator={uiValidator}
                            />
                        </>
                    ) : null}
                    {fields.selectedClasse === CLASSE_CHARGE_VARIABLE.code && (
                        <>
                            <CustomRow>
                                <CustomDropdown
                                    options={LIST_TYPE_CHARGE_VARIABLE.filter(
                                        (item) =>
                                            item.code !==
                                            TYPE_CHARGE_VARIABLE_COMMISSION.code
                                    )}
                                    label="Type*"
                                    labelClassName="col-12 col-sm-2"
                                    divClassName="col-12 col-sm-10"
                                    name="selectedTypeChargeVariable"
                                    id="selectedTypeChargeVariableCreationCharge"
                                    defaultOption="Selectionnez type charge"
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    uiValidator={uiValidator}
                                />
                            </CustomRow>
                            {fields.selectedTypeChargeVariable.code ===
                            TYPE_CHARGE_VARIABLE_RETRIBUTION.code ? (
                                <CustomRow>
                                    <CustomDropdown
                                        options={
                                            LIST_TYPE_ENTITE_CHARGE_VARIABLE
                                        }
                                        label="Type Charge*"
                                        labelClassName="col-12 col-sm-2"
                                        divClassName="col-12 col-sm-10"
                                        name="selectedTypeEntite"
                                        id="selectedTypeEntiteCreationChargeId"
                                        defaultOption="Selectionnez type entite"
                                        formDispatcher={formDispatcher}
                                        formState={formState}
                                        uiValidator={uiValidator}
                                    />
                                </CustomRow>
                            ) : null}
                            <CustomRow>
                                <CustomDropdown
                                    options={servicesGenerateur}
                                    label="Service generateur*"
                                    labelClassName="col-12 col-sm-2"
                                    divClassName="col-12 col-sm-10"
                                    name="selectedServiceGenerateur"
                                    id="selectedServiceGenerateurCreationCharge"
                                    idProperty="id"
                                    defaultOption="Selectionnez service generateur"
                                    formDispatcher={formDispatcher}
                                    formState={formState}
                                    uiValidator={uiValidator}
                                />
                            </CustomRow>
                            <CustomRow>
                                <CustomSwitchButton
                                    id="estSousCreationCharge"
                                    text="Est sous"
                                    inputTitle="Est sous (commission, retribution ou prime)"
                                    formDispatcher={formDispatcher}
                                    name="estSous"
                                    divClassName="col-md-8"
                                    formState={formState}
                                    uiValidator={uiValidator}
                                />
                            </CustomRow>
                        </>
                    )}
                    {fields.selectedClasse === CLASSE_CHARGE_FIXE.code ? (
                        <CustomButton
                            isShouldBeRendered={
                                fields.selectedClasse ===
                                CLASSE_CHARGE_FIXE.code
                            }
                            type="button"
                            onClick={onAddCharge}
                            identifier="code"
                            className="col-sm-2 offset-sm-10"
                            text="Ajouter"
                        />
                    ) : null}
                    {fields.listCharges.length &&
                    fields.selectedClasse === CLASSE_CHARGE_FIXE.code ? (
                        <SimpleTable
                            identifier="code"
                            formDispatcher={formDispatcher}
                            data={fields.listCharges}
                            isShowDropButton
                            listSelectedItemName="listCharges"
                            tableClass="table table-hover shadow table-bordered table-responsive-sm"
                            filter={false}
                            bottomPagination={false}
                            isLinkToDetail={false}
                            mapper={[
                                {
                                    position: 1,
                                    displayName: 'Code',
                                    field: 'code'
                                },
                                {
                                    position: 2,
                                    displayName: 'designation',
                                    field: 'newCharge.designation'
                                },
                                {
                                    position: 3,
                                    displayName: 'classe',
                                    field: 'newCharge.classe'
                                }
                            ]}
                        />
                    ) : null}
                    <CustomConfirmButton
                        type="button"
                        backdrop="static"
                        text="Créer"
                        className="btn-primary btn mt-3 w-25"
                        confirmOnClick={handleSubmit}
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                        isActionEncours={
                            form.status === FORM_CONST.CREATION_EN_COURS
                        }
                    />
                </div>
            ) : null}
        </FormWrapper>
    );
    return renderContent();
}
