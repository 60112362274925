import { DB_NODE, GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CustomAlert } from '../alert';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST } from '../../util';
// import { REDUX_NODE_NAME } from '../../redux';
import { ConditionalRenderingWrapper, CustomRow } from '../container';
import { CustomInput } from '../input';

/**
 * Construit une dropdown où l'on peut sélectionner les comptes
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @param {Boolean} param0.isReleve form state du parent
 * @param {String} param0.label Label du dropdown, par défaut "Caisse : "
 * @param {String} param0.propertyName propriété où la valeur de la caisse selectionnée est enregistrée dans le form state, par defaut "selectedCaisse"
 * @param {String} param0.labelClassName className du label
 * @param {String} param0.divClassName className du div
 * @param {String} param0.propertyToDisplay nom de la propriété à afficher dans le dropdown, par défaut : "designation"
 * @param {String} param0.idProperty propriété id à utiliser, par défaut : "id"
 * @returns {React.Component}
 */
export function SeviceClientClientRetraitSelector({
    formDispatcher,
    formState
}) {
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        // nappUser,
        monnaiesLocales,
        etatMonnaieLocale
        // marchandKindb,
        // etatMarchandKindb
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser,
        monnaiesLocales: reduxState.monnaiesLocale.map((item) => ({
            code: item?.devise,
            designation: item?.devise
        })),
        etatMonnaieLocale: reduxState.etat.monnaiesLocale.etat
        // marchandKindb: reduxState.marchandKindb.map((item) => ({
        //     ...item,
        //     designation: `${item?.marchand?.designation}`,
        //     code: `${item?.marchand?.code}`
        // })),
        // etatMarchandKindb: reduxState.etat.marchandKindb.etat
    }));

    // const { hasService } = useHasService({
    //     services: [
    //         CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
    //             .LECTURE_ALL_SUPERDEALER_AND_CASHCOLLECTEUR_CAISSE
    //     ]
    // });

    // const formater = (tableau = []) =>
    //     tableau.map((item) => ({
    //         ...item,
    //         code: item.id,
    //         designation: `${item?.designation || ''} - ${item?.devise || ''}`
    //     }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MONNAIE_LOCALE,
                nodeName: DB_NODE.MONNAIE_LOCALE,
                etat: etatMonnaieLocale
            }
            // {
            //     isOnlyFetch: true,
            //     functionName: URL_CONST.GET_LIST_MARCHAND_KINDB,
            //     nodeName: REDUX_NODE_NAME.MARCHAND_KINDB,
            //     etat: etatMarchandKindb
            // }
        ]
    });
    const { fields } = formState;

    const renderContent = () => {
        if (
            // etatMarchandKindb ===
            //     GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS &&
            etatMonnaieLocale === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <>
                    <CustomRow>
                        <CustomDropdown
                            label="Critère de Recherche*: "
                            labelClassName="col-12 col-sm-12"
                            divClassName="col-12 col-sm-12"
                            options={[
                                {
                                    code: 'CLIENT',
                                    designation: 'Numéro Client'
                                },
                                {
                                    code: 'REFERENCE',
                                    designation: 'Numéro de référence'
                                }
                            ]}
                            formDispatcher={formDispatcher}
                            formState={formState}
                            defaultOption="Selectionnez Critère"
                            name="selectedTypeOperation"
                            id="selectedTypeOperationRechercheOperationId"
                            // disabled={!superDealerKinDB?.length}
                            // uiValidator={uiValidator}
                        />
                    </CustomRow>
                    {/* <CustomRow>
                        <CustomDropdown
                            options={marchandKindb}
                            propertyToDisplay="designation"
                            idProperty="code"
                            label="Marchand"
                            labelClassName="col-12"
                            divClassName="col-12"
                            placeholder="Sélectionner un Marchand"
                            formDispatcher={formDispatcher}
                            defaultOption="Selectionnez Marchand"
                            name="selectedMarchand"
                            id="creationUploadFichierEmoneyInstitutionFinanciere"
                            // uiValidator={ProduitSchema}
                            formState={formState}
                            disabled={!marchandKindb.length}
                        />
                    </CustomRow> */}
                    <CustomRow
                    // isShouldBeRendered={
                    //     fields.selectedTypeOperation.code === 'CLIENT'
                    // }
                    >
                        <CustomInput
                            isInputGroup
                            // isFloat
                            type="text"
                            label="Numéro: *"
                            labelClassName="col-12"
                            divClassName="col-12"
                            placeholder="Saisissez le numéro"
                            formDispatcher={formDispatcher}
                            name="numero"
                            id="numero"
                            // uiValidator={uiValidator}
                            formState={formState}
                        />
                    </CustomRow>
                    <CustomRow
                        isShouldBeRendered={
                            fields.selectedTypeOperation.code === 'REFERENCE'
                        }
                    >
                        <CustomInput
                            isInputGroup
                            label="Référence*"
                            labelClassName="col-12"
                            divClassName="col-12"
                            placeholder="Saisissez le numéro de référence"
                            formDispatcher={formDispatcher}
                            name="numeroReference"
                            id="numeroReferenceRechercherOperationClient"
                            // uiValidator={uiValidator}
                            formState={formState}
                        />
                    </CustomRow>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={
                            fields.selectedTypeOperation.code === 'REFERENCE'
                        }
                    >
                        {/* <CustomRow>
                            <CustomInput
                                isInputGroup
                                // isFloat
                                type="text"
                                label="Till Number: *"
                                labelClassName="col-12"
                                divClassName="col-12"
                                placeholder="Saisissez le Till-Number"
                                formDispatcher={formDispatcher}
                                name="tillNumber"
                                id="tillNumberClientId"
                                // uiValidator={uiValidator}
                                formState={formState}
                            />
                        </CustomRow> */}
                        <CustomRow>
                            <CustomInput
                                isInputGroup
                                isFloat
                                type="number"
                                label="Montant: *"
                                labelClassName="col-12"
                                divClassName="col-12"
                                placeholder="Saisissez le montant"
                                formDispatcher={formDispatcher}
                                name="montant"
                                id="montantRechercherOperationClient"
                                // uiValidator={uiValidator}
                                formState={formState}
                            />
                        </CustomRow>
                        <CustomRow>
                            <CustomDropdown
                                options={monnaiesLocales}
                                defaultOption="Selectionner Devise"
                                disabled={!monnaiesLocales.length}
                                label="Devise*: "
                                labelClassName="col-12 col-sm-12"
                                divClassName="col-12 col-sm-12"
                                formDispatcher={formDispatcher}
                                name="selectedDevise"
                                id="selectedDeviseRechercherOperationId"
                                formState={formState}
                                // idProperty="id"
                                // uiValidator={uiValidator}
                            />
                        </CustomRow>
                    </ConditionalRenderingWrapper>
                </>
            );
        }
        if (
            // etatMarchandKindb === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC ||
            etatMonnaieLocale === GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des comtpes" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
