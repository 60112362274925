import {
    submitPutAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import { FORM_CONST, formInitialState } from '@napp-inc/jnapp-util';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatPhoneNumber, URL_CONST } from '../../../../util';
import {
    ConditionalRenderingWrapper,
    CustomAlert,
    CustomButtonLoader,
    CustomDropdown,
    CustomInput,
    CustomRow,
    FormWrapper
} from '../../../../components';

const defaultFields = {
    selectedType: {},
    numeroDestinataire: '',
    email: '',
    tillNumber: '',
    numeroEmoneyDestinataire: ''
};

export function RenvoieSmsUser() {
    // const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    // const fetchMarchand = () => {
    //     const { tillNumber } = fields;
    //     if (!tillNumber) return;
    //     if (tillNumber) {
    //         formDispatcher(
    //             fetchElement({
    //                 idToken,
    //                 elementPropertyName: 'user',
    //                 payload: {
    //                     tillNumber,
    //                     estDetailEntreprise: true,
    //                     typeEntite:
    //                         TYPE_ORGANISATION.ORGANISATION_REVENDEUR.code
    //                 },
    //                 functionName: URL_CONST.GET_USER_BY_TILL_NUMBER,
    //                 etat: elements.user.status,
    //                 formDispatcher,
    //                 reduxDispatcher
    //             })
    //         );
    //     }
    // };
    const OPERATIONS = [
        {
            code: 'ENTREPRISE',
            designation: 'Création Organisation',
            node: 'Entreprise'
        },
        {
            code: 'NUMERO_EMONEY',
            designation: 'Enregistrement Numéro Emoney',
            node: 'Compte'
        }
    ];
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = { estServiceClient: true };
        if (fields.selectedType.code === 'ENTREPRISE') {
            payload.collection = 'Entreprise';
            payload.email = fields.email;
            if (fields.numeroDestinataire) {
                payload.numeroTel = `243${formatPhoneNumber({
                    numero: fields.numeroDestinataire
                })}`;
            }
        }
        if (fields.selectedType.code === 'NUMERO_EMONEY') {
            payload.collection = 'Compte';
            payload.numero = formatPhoneNumber({
                numero: fields.numeroEmoneyDestinataire,
                isFormat: false
            });
            payload.tillNumber = fields.tillNumber;
            payload.numeroTel = `243${formatPhoneNumber({
                numero: fields.numeroDestinataire
            })}`;
        }
        formDispatcher(
            submitPutAction({
                idToken,
                functionName: URL_CONST.PUT_RELANCER_SMS,
                payload,
                fields
            })
        );
    };
    const renderContent = () => (
        <FormWrapper
            isRedirect={false}
            isBackComponent={false}
            isStepBack={false}
            onSubmit={handleSubmit}
            formState={formState}
            formDispatcher={formDispatcher}
        >
            {form.status === FORM_CONST.MODIFICATION_SUCCESS ? (
                <CustomAlert successMessage="Opération effectuée avec succès!" />
            ) : null}
            {/* {elements.user.status === FORM_CONST.FETCH_ELEMENT_FAILED ? (
                <CustomAlert error={`Erreur: ${elements.user.error || ''}`} />
            ) : null} */}
            <CustomRow>
                <CustomDropdown
                    options={OPERATIONS}
                    formDispatcher={formDispatcher}
                    formState={formState}
                    defaultOption="Sélectionner Opération"
                    label="Opération*"
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    name="selectedType"
                    id="selectedTypeResetPinId"
                    // uiValidator={uiValidator}
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={fields.selectedType.code === 'ENTREPRISE'}
            >
                <CustomRow>
                    <CustomInput
                        type="email"
                        isInputGroup={false}
                        label="Email*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        placeholder="Saisissez l'email de l'entreprise"
                        formDispatcher={formDispatcher}
                        name="email"
                        id="emailRenvoieSmsUserId"
                        formState={formState}
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Déstinataire*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        placeholder="Saisissez le numéro du déstinataire"
                        formDispatcher={formDispatcher}
                        name="numeroDestinataire"
                        id="numeroDestinataireRenvoieSmsUserId"
                        formState={formState}
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    fields.selectedType.code === 'NUMERO_EMONEY'
                }
            >
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Till Number*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        placeholder="Saisissez le till number de l'utilisateur"
                        formDispatcher={formDispatcher}
                        name="tillNumber"
                        id="tillNumberId"
                        formState={formState}
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Numéro Emoney*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        placeholder="Saisissez le numéro emoney"
                        formDispatcher={formDispatcher}
                        name="numeroEmoneyDestinataire"
                        id="numeroEmoneyDestinataireRenvoieSmsUserId"
                        formState={formState}
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Déstinataire*"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        placeholder="Saisissez le numéro du déstinataire"
                        formDispatcher={formDispatcher}
                        name="numeroDestinataire"
                        id="numeroDestinataireRenvoieSmsUserId"
                        formState={formState}
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow isShouldBeRendered={!!fields?.selectedType?.code}>
                <CustomButtonLoader
                    className="btn-primary col-sm-2 ms-2"
                    onClick={handleSubmit}
                    text="Renvoyer SMS"
                    disabled={form.status === FORM_CONST.MODIFICATION_EN_COURS}
                />
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
