import { fromTimestampToString } from '@napp-inc/jnapp-util';

/**
 * Formats the type of budget based on the item's properties.
 *
 * @param {Object} param - The parameter object.
 * @param {Object} param.item - The item object containing budget details.
 * @param {string} param.item.typeProprietaire - The type of the owner (e.g., 'Ressource', 'Charge').
 * @param {boolean} [param.item.estVariable] - Indicates if the item is variable (only applicable if typeProprietaire is 'Charge').
 * @returns {string} - The formatted budget type.
 */
function formatTypeBuget({ item }) {
    if (item.typeProprietaire === 'Ressource') return item.typeProprietaire;
    if (item.typeProprietaire === 'Charge' && !!item?.estVariable)
        return `${item.typeProprietaire} Variable`;
    return `${item.typeProprietaire} Fixe`;
}

/**
 * Formats a list of budget data.
 *
 * @param {Array} [data=[]] - The array of budget data to format.
 * @returns {Array} The formatted budget data.
 */
export function budgetListFormater(data = []) {
    return data.map((item) => ({
        ...item,
        typeBudget: formatTypeBuget({ item }),
        operation: item.typeEntite,
        exercice: `${fromTimestampToString(
            item.budget.dateDebut,
            'yyyy'
        )}-${fromTimestampToString(item.budget.dateFin, 'yyyy')}`
    }));
}

/**
 * Formats the budget detail item.
 *
 * @param {Object} item - The budget item to format.
 * @param {Object} [item.budget] - The budget details.
 * @param {number} [item.budget.dateDebut] - The start date of the budget as a timestamp.
 * @param {number} [item.budget.dateFin] - The end date of the budget as a timestamp.
 * @param {string} [item.typeEntite] - The type of entity for the operation.
 * @returns {Object} The formatted budget detail item.
 */
export function budgetDetailFormater(item = {}) {
    return {
        ...item,
        typeBudget: formatTypeBuget({ item }),
        operation: item.typeEntite,
        exercice: `${fromTimestampToString(
            item.budget.dateDebut,
            'yyyy'
        )}-${fromTimestampToString(item.budget.dateFin, 'yyyy')}`
    };
}
