import { Fragment, useCallback, useEffect, useState } from 'react';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    DB_NODE,
    formInitialState,
    BUDGET_ELABORATION,
    CHARGE,
    TYPE_BUDGET_PREVISIONNEL,
    fromTimestampToString,
    TYPE_BUDGET_ADDITIONNEL,
    BUDGET_AMENDEMENT,
    TIME,
    MOYEN_PAIEMENT,
    GenericModel,
    ETAT
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { FaRegFilePdf } from 'react-icons/fa';
import {
    FormWrapper,
    ConditionalRenderingWrapper,
    CustomButton,
    CustomCenteredModal,
    // StateShowerComponent,
    BackComponent,
    CustomDropdown,
    CustomSwitchButton
} from '../../../../../components';
import { URL_CONST } from '../../../../../util';
import { REDUX_NODE_NAME } from '../../../../../redux';
import {
    handleAddHighLevel,
    handleChange,
    handleRemove,
    handleSelectChange,
    tablePreviewData,
    toggledView,
    useGeneratePayloadAndDataList
} from '../util';
import {
    ChargeVariableBaseForm,
    ElaborationTablePreview,
    LeftSidePannelVariable as LeftSidePannel
} from '../base';
import { getCustomUrl } from '../../../../../helpers';

/**
 * @description fields du state du formulaire
 */
const defaultFields = {
    selectedExercice: {},
    modePaiement: {},
    estPrevisionnel: true,
    selectedCaisse: {}
};

/**
 * @description dependencies du state du formulaire
 */

const defaultDependencies = {
    fields: ['charges', 'caisses', 'exercicesFinancier', 'brouillon']
};

/**
 * Principale vue des vues pour les charges variables
 * @param {Object} param0 : prends en compte toutes les props possibles
 * @param {String} param0.typeBudget: qui sera du type 'Elaboration | Completion | Ammendement'
 * @param {String} param0.isAdditionnel: qui sera du type true | False, pour savoir si c'est un prévisionnel ou un additionnel
 * @returns
 */
export function ChargesVariables({
    isAdditionnel = false,
    isCompletion = false,
    isAmendement = false,
    idEntity,
    payloadUrl = {}
}) {
    // const navigate = useNavigate();
    // const path = useLocation();
    const [show, setShow] = useState(false);
    // const [idTarget, setIdTarget] = useState(null);
    const onHide = () => setShow(false);
    const onShow = () => setShow(true);
    const [formFields, setFormFields] = useState([
        {
            element: {
                code: '',
                designation: ''
            },
            tauxCroissance: 0,
            isTooggled: true,
            isVisible: true,
            estRetribution: false
        }
    ]);
    const reduxDispatcher = useDispatch();
    const {
        firebaseUser,
        chargesVariable,
        etatCharge,
        caisses,
        etatCaisse,
        exerciceFinancier,
        etatExerciceFinancier,
        brouillons,
        etatBrouillon
    } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        chargesVariable: reduxState.chargesVariable,
        etatCharge: reduxState.etat.chargesVariable.etat,
        caisses: reduxState.caissesSectorielle.map((item) => ({
            ...item,
            designation: `${item.valeurTypeEntite.designation}-${item.devise}`
        })),
        etatCaisse: reduxState.etat.caissesSectorielle.etat,
        exerciceFinancier: reduxState.exerciceFinancier.map((item) => ({
            ...item,
            designation: `${fromTimestampToString(
                item.dateDebut
            )} - ${fromTimestampToString(item.dateFin)}`
        })),
        etatExerciceFinancier: reduxState.etat.exerciceFinancier.etat,
        brouillons: reduxState.brouillonChargeVariable.filter(
            (item) =>
                item.type ===
                    (isAdditionnel
                        ? TYPE_BUDGET_ADDITIONNEL.code
                        : TYPE_BUDGET_PREVISIONNEL.code) &&
                item.typeEntite ===
                    (isAmendement ? BUDGET_AMENDEMENT : BUDGET_ELABORATION) &&
                !!item.estVariable &&
                item.etat === ETAT.ETAT_ENCOURS
        ),
        etatBrouillon: reduxState.etat.brouillonChargeVariable.etat
    }));
    /**
     * @description: exercice financier format
     */
    const exercices = exerciceFinancier.map((item) => ({
        ...item,
        designation: `${TIME.fromUtcTimestampToLocalString(
            item.dateDebut
        )} - ${TIME.fromUtcTimestampToLocalString(item.dateFin)}`
    }));
    const { idToken, creator } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        dependencies: defaultDependencies
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;

    const exerciceCallback = useCallback(
        ({ data }) =>
            data.find(
                (item) =>
                    item.id === fields?.selectedExercice.id ||
                    (item.dateDebut === payloadUrl?.start &&
                        item.dateFin === payloadUrl?.end)
            ),
        [fields?.selectedExercice.id, payloadUrl?.end, payloadUrl?.start]
    );

    const exerciceCible = exerciceCallback({ data: exercices });

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CHARGE_VARIABLE,
                nodeName: REDUX_NODE_NAME.CHARGE_VARIABLE,
                etat: etatCharge
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_SECTORIELLE,
                nodeName: REDUX_NODE_NAME.CAISSE_SECTORIELLE,
                etat: etatCaisse
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_EXERCICE,
                nodeName: DB_NODE.EXERCICE_FINANCIER,
                etat: etatExerciceFinancier
                // isCondition: !payloadUrl?.start || !payloadUrl?.end
            },
            {
                isOnlyFetch: true,
                functionName:
                    URL_CONST.GET_LIST_BROUILLON_ELABORATION_AMENDEMENT_INITIALS,
                nodeName: REDUX_NODE_NAME.BROUILLON_CHARGE_VARIABLE,
                etat: etatBrouillon,
                payload: {
                    newElaborationAmendement: {
                        typeProprietaire: CHARGE.code,
                        estVariable: true
                    }
                }
                // isCondition: !payloadUrl?.start || !payloadUrl?.end
            }
        ]
    });

    /**
     * End Region
     */

    const { payloadList, tableList } = useGeneratePayloadAndDataList({
        formFields,
        tablePreviewData,
        fields,
        view: 'ChargeVariable'
    });

    const memoisedDraft = useCallback(
        ({ draft }) => {
            if (
                draft.length &&
                ((exerciceCible?.dateDebut && exerciceCible?.dateFin) ||
                    (payloadUrl?.start && payloadUrl?.end))
            ) {
                const payloadChecker = {
                    dateDebut: exerciceCible?.dateDebut || payloadUrl?.start,
                    dateFin: exerciceCible?.dateFin || payloadUrl?.end,
                    type: isAdditionnel
                        ? TYPE_BUDGET_ADDITIONNEL.code
                        : TYPE_BUDGET_PREVISIONNEL.code
                };
                if (fields.estPrevisionnel && isAdditionnel) {
                    payloadChecker.estPrevisionnel = fields.estPrevisionnel;
                }
                const findDraft =
                    fields.estPrevisionnel && isAdditionnel
                        ? draft.find(
                              (item) =>
                                  item?.periode?.dateDebut ===
                                      payloadChecker?.dateDebut &&
                                  item?.periode?.dateFin ===
                                      payloadChecker?.dateFin &&
                                  item?.type === payloadChecker?.type &&
                                  item?.estPrevisionnel ===
                                      payloadChecker?.estPrevisionnel
                          )
                        : draft.find(
                              (item) =>
                                  item?.periode?.dateDebut ===
                                      payloadChecker?.dateDebut &&
                                  item?.periode?.dateFin ===
                                      payloadChecker?.dateFin &&
                                  item?.type === payloadChecker?.type
                          );
                const parsedBruillon = findDraft?.brouillon?.list || [];
                setFormFields([
                    ...parsedBruillon,
                    {
                        element: {
                            code: '',
                            designation: ''
                        },
                        tauxCroissance: 0,
                        isTooggled: true,
                        isVisible: true,
                        estRetribution: false
                    }
                ]);
            }
        },
        [
            exerciceCible?.dateDebut,
            exerciceCible?.dateFin,
            fields.estPrevisionnel,
            isAdditionnel,
            payloadUrl?.end,
            payloadUrl?.start
        ]
    );

    /**
     * UseEffect pour mettre à jour le state au cas où un brouillon existe
     */
    useEffect(() => {
        memoisedDraft({ draft: brouillons || [] });
        return memoisedDraft({ draft: [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoisedDraft]);

    const handlePostDraft = ({ isDraft = false }) => {
        if (isDraft) {
            const payload = {
                periode: {
                    dateDebut: exerciceCible.dateDebut || payloadUrl?.start,
                    dateFin: exerciceCible.dateFin || payloadUrl?.end
                },
                newElaborationAmendement: {
                    typeEntite: isAmendement
                        ? BUDGET_AMENDEMENT
                        : BUDGET_ELABORATION,
                    typeProprietaire: CHARGE.code,
                    estVariable: true,
                    type: isAdditionnel
                        ? TYPE_BUDGET_ADDITIONNEL.code
                        : TYPE_BUDGET_PREVISIONNEL.code
                },
                creator
            };
            if (isAdditionnel) {
                payload.newElaborationAmendement.estPrevisionnel =
                    fields.estPrevisionnel;
            }
            if ((isCompletion || isAmendement) && idEntity) {
                payload.idElaborationAmendement = idEntity;
            }
            if (formFields.length) {
                payload.elemUpdate = {
                    list: formFields.filter((item) => item.element.code)
                };
            }
            GenericModel.postObjetViaCF({
                idToken,
                customUrl: getCustomUrl({
                    functionName:
                        URL_CONST.POST_BROUILLON_ELABORATION_AMENDEMENT
                }),
                // functionName: URL_CONST.POST_BROUILLON_ELABORATION_AMENDEMENT,
                ...payload
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const list = payloadList;
        const payload = {
            periode: {
                dateDebut: exerciceCible.dateDebut || payloadUrl?.start,
                dateFin: exerciceCible.dateFin || payloadUrl?.end
            },
            newElaborationAmendement: {
                typeEntite: isAmendement
                    ? BUDGET_AMENDEMENT
                    : BUDGET_ELABORATION,
                typeProprietaire: CHARGE.code,
                estVariable: true,
                type: isAdditionnel
                    ? TYPE_BUDGET_ADDITIONNEL.code
                    : TYPE_BUDGET_PREVISIONNEL.code
            },
            creator,
            list
        };
        if (isAdditionnel) {
            payload.newElaborationAmendement.estPrevisionnel =
                fields.estPrevisionnel;
        }
        if (isCompletion || isAmendement) {
            payload.idElaborationAmendement = idEntity;
        }
        // console.log(JSON.stringify(payload));
        handlePostDraft({ isDraft: true });
        formDispatcher(
            submitPostAction({
                idToken,
                customUrl: getCustomUrl({
                    functionName: URL_CONST.POST_ELABORATION_AMENDEMENT
                }),
                // functionName: URL_CONST.POST_ELABORATION_AMENDEMENT,
                reduxNodeName: DB_NODE.ELABORATION_AMENDEMENT,
                payload,
                fields,
                reduxDispatcher
                // uiValidator
            })
        );
    };

    /**
     * Fonction de suppression d'une charge à partir du pannel
     * @param {*} param0
     */
    // const removeView = ({ code }) => {
    //     const index = formFields.map((item) => item.element.code).indexOf(code);
    //     handleRemove(index);
    // };

    /**
     * Options définies pour la prévisualisation en pdf
     */
    const options = {
        // default is `save`
        method: 'open',
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.HIGH,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: 'letter',
            // default is 'portrait'
            orientation: 'landscape'
        },
        canvas: {
            // default is 'image/jpeg' for better size performance
            mimeType: 'image/png',
            qualityRatio: 1
        },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break,
        // so use with caution.
        overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
                compress: true
            },
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
                useCORS: true
            }
        }
    };
    // you can use a function to return the target element besides using React refs
    const getTargetElement = () => document.getElementById('content-id');

    const renderContent = () => (
        <>
            <ConditionalRenderingWrapper
                isShouldBeRendered={!payloadUrl?.start || !payloadUrl?.end}
            >
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 border-bottom">
                    <BackComponent className="btn btn-primary" />
                    <ConditionalRenderingWrapper isShouldBeRendered={!idEntity}>
                        <div className="d-flex justify-content-between align-items-center col-4">
                            <CustomDropdown
                                options={exercices}
                                formDispatcher={formDispatcher}
                                formState={formState}
                                defaultOption="Sélectionner un exercice"
                                label="Exercice: "
                                selectionClassName="form-select-sm fw-bold border-dark"
                                labelClassName="col-12 col-sm-2 col-md-2 fs-5 me-5 fw-bold"
                                divClassName="col-12 col-sm-10 col-md-10"
                                name="selectedExercice"
                                id="selectedExerciceId"
                                idProperty="id"
                            />
                        </div>
                    </ConditionalRenderingWrapper>
                    <ConditionalRenderingWrapper
                        isShouldBeRendered={isAdditionnel}
                    >
                        <CustomSwitchButton
                            id="selectionElaborationAdditionnelSwitchId"
                            name="estPrevisionnel"
                            text="Agir sur le Prévisionnel?"
                            value={fields.estPrevisionnel}
                            formState={formState}
                            formDispatcher={formDispatcher}
                            labelClassName="fst-italic fw-bolder"
                        />
                    </ConditionalRenderingWrapper>
                    <h3>
                        Budget {isAdditionnel ? 'Additionnel' : 'Présionnel'}
                    </h3>
                </div>
            </ConditionalRenderingWrapper>
            <FormWrapper
                isRedirect={!isCompletion && !isAmendement}
                onSubmit={handleSubmit}
                formState={formState}
                isStepBack={isCompletion || isAmendement}
                formDispatcher={formDispatcher}
                listDependencies={[
                    {
                        dependency: 'exercicesFinancier',
                        etat: etatExerciceFinancier
                        // isCondition: !payloadUrl?.start || !payloadUrl?.end
                    },
                    {
                        dependency: 'charges',
                        etat: etatCharge
                    },
                    {
                        dependency: 'caisses',
                        etat: etatCaisse
                    },
                    {
                        dependency: 'brouillon',
                        etat: etatBrouillon
                        // isCondition: !payloadUrl?.start || !payloadUrl?.end
                    }
                ]}
            >
                <div className="d-flex">
                    <LeftSidePannel
                        onShow={onShow}
                        formFields={formFields}
                        // toggledVisibilityView={toggledVisibilityView}
                        toggledView={toggledView}
                        handleSubmit={handleSubmit}
                        form={form}
                        setFormFields={setFormFields}
                        handleRemove={handleRemove}
                    />
                    <div className="col-9 ms-2">
                        <CustomCenteredModal
                            show={show}
                            onHide={onHide}
                            header="Elaboration"
                            fullscreen
                            // size="lg"
                            // backdrop={backdrop}
                        >
                            <div className="text-end mb-2">
                                <button
                                    className="btn btn-primary rounded-pill"
                                    type="button"
                                    onClick={() =>
                                        generatePDF(getTargetElement, options)
                                    }
                                >
                                    Visualiser <FaRegFilePdf />
                                </button>
                            </div>
                            <div id="content-id">
                                <ElaborationTablePreview data={tableList} />
                            </div>
                        </CustomCenteredModal>
                        <ConditionalRenderingWrapper
                            isShouldBeRendered={
                                !!fields.selectedExercice.id ||
                                payloadUrl?.start ||
                                payloadUrl?.end
                            }
                        >
                            <div className="row d-flex align-items-center justify-content-between my-3">
                                <div className="col">
                                    <CustomDropdown
                                        options={
                                            MOYEN_PAIEMENT.ALL_MOYEN_PAIEMENT
                                        }
                                        formDispatcher={formDispatcher}
                                        formState={formState}
                                        defaultOption="Sélectionner le mode de paiement"
                                        label="Mode de paiement*"
                                        selectionClassName="form-select form-select-sm col-3"
                                        labelClassName="col-6 col-sm-6 col-md-6 me-5"
                                        divClassName="col-12 col-sm-12 col-md-12"
                                        name="modePaiement"
                                        id="modePaiementId"
                                        labelStyle={{
                                            fontSize: '13px',
                                            // paddingBottom: '8px',
                                            lineHeight: '20px',
                                            minHeight: '20px',
                                            display: 'block',
                                            color: 'rgba(0,0,0,0.5)'
                                        }}
                                        // uiValidator={uiValidator}
                                    />
                                </div>
                                <ConditionalRenderingWrapper
                                    isShouldBeRendered={
                                        fields.modePaiement.code === 'Cash'
                                    }
                                >
                                    <div className="col">
                                        <CustomDropdown
                                            labelStyle={{
                                                fontSize: '13px',
                                                // paddingBottom: '8px',
                                                lineHeight: '20px',
                                                minHeight: '20px',
                                                display: 'block',
                                                color: 'rgba(0,0,0,0.5)'
                                            }}
                                            idProperty="id"
                                            options={caisses}
                                            formDispatcher={formDispatcher}
                                            formState={formState}
                                            defaultOption="Sélectionner la caisse"
                                            label="Caisse*"
                                            selectionClassName="form-select form-select-sm col-3"
                                            // labelClassName="col-2 col-sm-2 col-md-2 me-5"
                                            divClassName="col-12 col-sm-12 col-md-12"
                                            name="selectedCaisse"
                                            id="selectedCaisseId"
                                            // uiValidator={uiValidator}
                                        />
                                    </div>
                                </ConditionalRenderingWrapper>
                            </div>
                            {formFields.map((field, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Fragment key={index}>
                                    {field.isTooggled ? (
                                        <ChargeVariableBaseForm
                                            caisses={caisses}
                                            charges={chargesVariable}
                                            field={field}
                                            formFields={formFields}
                                            setFormFields={setFormFields}
                                            handleAddHighLevel={
                                                handleAddHighLevel
                                            }
                                            handleChange={handleChange}
                                            handleSelectChange={
                                                handleSelectChange
                                            }
                                            index={index}
                                            // onToggleView={toggledView}
                                        />
                                    ) : null}
                                </Fragment>
                            ))}
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    type="button"
                                    text="+ Ajouter"
                                    className="btn-success btn"
                                    onClick={() => {
                                        handleAddHighLevel({
                                            type: 'Charge',
                                            setFormFields,
                                            formFields,
                                            view: `ChargeVariable`
                                        });
                                        handlePostDraft({ isDraft: true });
                                    }}
                                />
                            </div>
                            {/* <div className="d-flex justify-content-between">
                                <div className="col-5">
                                    <StateShowerComponent state={formFields} />
                                </div>
                                <div className="col-5">
                                    <StateShowerComponent state={fields} />
                                </div>
                            </div> */}
                        </ConditionalRenderingWrapper>
                    </div>
                </div>
            </FormWrapper>
        </>
    );
    return renderContent();
}
