import { DB_NODE, ONLY_FETCH, URL_CONST } from '@napp-inc/jnapp-util';
import { ListGenericForm } from '../../../../components';
import { budgetListFormater } from './util/functions';

export function ListBudget() {
    const renderContent = () => (
        <ListGenericForm
            fetchType={ONLY_FETCH}
            reduxPropertyName="elaborations"
            functionName={URL_CONST.GET_LIST_ELABORATION_AMENDEMENT_INITIALS}
            nodeName={DB_NODE.ELABORATION_AMENDEMENT}
            listFormater={budgetListFormater}
            mapper={[
                {
                    field: 'type',
                    displayName: 'Budget'
                },
                {
                    field: 'exercice',
                    displayName: 'Période'
                },
                {
                    field: 'estPrevisionnel',
                    displayName: 'Action sur prévisionnel'
                },
                {
                    field: 'operation',
                    displayName: 'Opération'
                },
                {
                    field: 'typeBudget',
                    displayName: 'Type'
                },
                {
                    field: 'etat',
                    displayName: 'etat'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création'
                },
                {
                    field: 'dateDerniereModification',
                    displayName: 'Modification'
                }
            ]}
        />
    );
    return renderContent();
}
