export const formatCodeDesignation = ({ partToAdd = '', designation }) => {
    if (designation && typeof designation === 'string') {
        return `${partToAdd}${designation
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase()
            .replace(/\s/g, '_')}`;
    }
    return designation;
};
