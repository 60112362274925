import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { Report } from '../../../module';
import {
    CasLitigieuxDetailRelPath,
    CasLitigieuxEmoneyDetailRelPath,
    CasLitigieuxEmoneyViewLayoutRelPath,
    CasLitigieuxViewLayoutRelPath,
    CommandeFlashRelPath,
    DetailMouvementOperationRelPath,
    DetailMouvementRelPath,
    FondsRoulementKinDBRelPath,
    ListMouvementOperationRelPath,
    ListMouvementRelPath,
    RapportActiviteViewLayoutRelPath,
    RapportAgentKinDBRelPath,
    RapportBalanceKinDBRelPath,
    RapportCaisseKinDBRelPath,
    RapportCommandeEmoneyViewLayoutRelPath,
    RapportInstitutionFinanciereKinDBRelPath,
    RapportOperationBancaireViewLayoutRelPath,
    RapportPartenaireKinDBRelPath,
    RapportStockKinDBRelPath,
    RapportTropPercuViewLayoutRelPath,
    ReleveCaisseEmoneyViewLayoutRelPath,
    ReleveCaisseViewLayoutRelPath,
    ReleveCompteViewLayoutRelPath,
    ReleveEmoneyViewLayoutRelPath,
    FondsRoulementCashCollecteurRelPath,
    RapportPartenaireCashCollecteurRelPath,
    VenteRevendeurRelPath,
    RapportCaisseCashCollecteurRelPath,
    RapportAgentCashCollecteurRelPath,
    RapportInstitutionFinanciereCashCollecteurRelPath,
    RapportBalanceCashCollecteurRelPath,
    RapportAgentRevendeurRelPath,
    RapportCaisseRevendeurRelPath,
    RapportBalanceRevendeurRelPath,
    RapportCommissionRevendeurRelPath,
    RapportLiaisonKinDBRelPath,
    RapportCautionKinDBRelPath,
    RapportRetraitNonDeclareKinDBRelPath,
    RapportRecruteurRevendeurRelPath,
    RapportLiaisonCashCollecteurRelPath,
    RapportLiaisonRevendeurRelPath,
    RapportEvolutionBalanceOrganisationRelPath,
    RapportCaisseEmoneyOrganisationViewLayoutRelPath,
    RapportEvolutionStockOrganisationViewLayoutRelPath,
    RapportPresenceAgentViewLayoutRelPath,
    RapportComptabilisationCompteOrganisationViewLayoutRelPath,
    RapportStockSimKinDBRelPath,
    RapportGainPerteChangeRelPath,
    RapportGainPerteVenteRelPath,
    RapportRegularisationProduitRelPath,
    RapportRegularisationCaisseRelPath,
    RapportRegularisationStockRelPath,
    RapportComptabilisationKinDBRelPath,
    BasePerformanceKinDBRelPath
} from './report-rel-path';

export const DetailMouvementPath = {
    ...DetailMouvementRelPath,
    dependencies: ['PrincipalMenu'],
    Component: Report.DetailMouvement,
    element: <Report.DetailMouvement />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.MOUVEMENT
            .LECTURE_TOUT_MOUVEMENT
    ]
};

export const ListMouvementPath = {
    ...ListMouvementRelPath,
    dependencies: ['PrincipalMenu'],
    Component: Report.ListMouvement,
    element: <Report.ListMouvement />,
    isVisible: true,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.MOUVEMENT
            .LECTURE_TOUT_MOUVEMENT
    ]
};

export const DetailMouvementOperationPath = {
    ...DetailMouvementOperationRelPath,
    dependencies: ['PrincipalMenu'],
    Component: Report.DetailMouvementOperation,
    element: <Report.DetailMouvementOperation />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.MOUVEMENT
            .LECTURE_TOUT_MOUVEMENT
    ]
};

export const ListMouvementOperationPath = {
    ...ListMouvementOperationRelPath,
    dependencies: ['PrincipalMenu'],
    Component: Report.ListMouvementOperation,
    element: <Report.ListMouvementOperation />,
    isVisible: true,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.MOUVEMENT
            .LECTURE_TOUT_MOUVEMENT
    ]
};

export const ReleveCompteViewLayoutPath = {
    ...ReleveCompteViewLayoutRelPath,
    dependencies: ['PrincipalMenu'],
    Component: Report.ReleveCompteViewLayout,
    element: <Report.ReleveCompteViewLayout />,
    isVisible: true,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_COMPTE
    ]
};

export const ReleveEmoneyViewLayoutPath = {
    ...ReleveEmoneyViewLayoutRelPath,
    dependencies: ['PrincipalMenu'],
    Component: Report.ReleveEmoneyViewLayout,
    element: <Report.ReleveEmoneyViewLayout />,
    isVisible: true,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.CREATION_RELEVE_EMONEY,
        CODE_SERVICE.STOCK.COMMANDE_MARCHAND.LECTURE_COMMANDE_EMONEY_MARCHAND
    ]
};

export const RapportComptabilisationCompteOrganisationViewLayoutPath = {
    ...RapportComptabilisationCompteOrganisationViewLayoutRelPath,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportComptabilisationCompteOrganisationViewLayout,
    element: <Report.RapportComptabilisationCompteOrganisationViewLayout />,
    isVisible: true,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

export const ReleveCaisseViewLayoutPath = {
    ...ReleveCaisseViewLayoutRelPath,
    dependencies: ['PrincipalMenu'],
    Component: Report.ReleveCaisseViewLayout,
    element: <Report.ReleveCaisseViewLayout />,
    isVisible: true,
    code: [{ code: 'test' }]
};

export const ReleveCaisseEmoneyViewLayoutPath = {
    ...ReleveCaisseEmoneyViewLayoutRelPath,
    dependencies: ['PrincipalMenu'],
    Component: Report.ReleveCaisseEmoneyViewLayout,
    element: <Report.ReleveCaisseEmoneyViewLayout />,
    isVisible: true,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.LECTURE_RELEVE_CAISSE_EMONEY
    ]
};

export const CommandeFlashPath = {
    ...CommandeFlashRelPath,
    dependencies: ['PrincipalMenu'],
    Component: Report.ReleveCommandeFlashViewLayout,
    element: <Report.ReleveCommandeFlashViewLayout />,
    isVisible: true,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.COMMANDE_MARCHAND.LECTURE_COMMANDE_FLASH_MARCHAND
    ]
};

/**
 * @desc Cas litigieux
 */

export const CasLitigieuxViewLayoutPath = {
    ...CasLitigieuxViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.CasLitigieuxViewLayout,
    element: <Report.CasLitigieuxViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_SENDER.MISE_A_JOUR_COMMANDE_LITIGIEUX,
        CODE_SERVICE.CODE_SERVICE_SENDER.LECTURE_COMMANDE_LITIGIEUX
    ]
};

export const CasLitigieuxDetailPath = {
    ...CasLitigieuxDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Report.DetailCasLitigieuxForm,
    element: <Report.DetailCasLitigieuxForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_SENDER.MISE_A_JOUR_COMMANDE_LITIGIEUX,
        CODE_SERVICE.CODE_SERVICE_SENDER.LECTURE_COMMANDE_LITIGIEUX
    ]
};

// FIXME: TO REVIEW, this path has no service
export const RapportOperationBancaireViewLayoutPath = {
    ...RapportOperationBancaireViewLayoutRelPath,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportOperationBancaireViewLayout,
    element: <Report.RapportOperationBancaireViewLayout />,
    isVisible: true,
    code: [
        { code: 'test' }
        // CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
        //     .LECTURE_RAPPORT_COMPTE
    ]
};
/**
 * @desc Rapport trop perçu
 */

export const RapportTropPercuViewLayoutPath = {
    ...RapportTropPercuViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportTropPercuViewLayout,
    element: <Report.RapportTropPercuViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_LIST_STOCK_SUPER_DEALER
    ]
};
/**
 * @desc Cas litigieux emoney
 */

export const CasLitigieuxEmoneyViewLayoutPath = {
    ...CasLitigieuxEmoneyViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.CasLitigieuxEmoneyViewLayout,
    element: <Report.CasLitigieuxEmoneyViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_SENDER.MISE_A_JOUR_COMMANDE_LITIGIEUX_EMONEY,
        CODE_SERVICE.CODE_SERVICE_SENDER.LECTURE_COMMANDE_LITIGIEUX_EMONEY
    ]
};

export const CasLitigieuxEmoneyDetailPath = {
    ...CasLitigieuxEmoneyDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Report.DetailCasLitigieuxEmoneyForm,
    element: <Report.DetailCasLitigieuxEmoneyForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_SENDER.MISE_A_JOUR_COMMANDE_LITIGIEUX_EMONEY,
        CODE_SERVICE.CODE_SERVICE_SENDER.LECTURE_COMMANDE_LITIGIEUX_EMONEY
    ]
};

/**
 * @desc Cas litigieux emoney
 */

export const RapportCommandeEmoneyViewLayoutPath = {
    ...RapportCommandeEmoneyViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportCommandeEmoneyViewLayout,
    element: <Report.RapportCommandeEmoneyViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.STOCK.COMMANDE_MARCHAND.LECTURE_COMMANDE_EMONEY_MARCHAND
    ]
};

/**
 * @desc Rapport Présence
 */

export const RapportPresenceAgentViewLayoutPath = {
    ...RapportPresenceAgentViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportPresenceAgentViewLayout,
    element: <Report.RapportPresenceAgentViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .TRAITEMENT_FICHIER_PRESENCE.LECTURE_RAPPORT_PRESENCE_ORGANISATION
    ]
};

/**
 * @desc Rapport activite
 */

export const RapportActiviteViewLayoutPath = {
    ...RapportActiviteViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportActiviteViewLayout,
    element: <Report.RapportActiviteViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY,
        CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY_MODE_RECOUVREMENT
    ]
};

/**
 * @desc Fonds roulement kindb
 */

export const FondsRoulementKinDBViewLayoutPath = {
    ...FondsRoulementKinDBRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.FondsRoulementKinDBViewLayout,
    element: <Report.FondsRoulementKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Rapport stock kindb
 */

export const RapportStockKinDBViewLayoutPath = {
    ...RapportStockKinDBRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportStockKinDBViewLayout,
    element: <Report.RapportStockKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_LIST_STOCK_SUPER_DEALER,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
            .LECTURE_LIST_RAPPORT_ORGANISATION
    ]
};

/**
 * @desc Rapport stock Sim kindb
 */

export const RapportStockSimKinDBViewLayoutPath = {
    ...RapportStockSimKinDBRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportStockSimKinDBViewLayout,
    element: <Report.RapportStockSimKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT,
        CODE_SERVICE.STOCK.FACTURATION_STOCK.LECTURE_FACTURATION_STOCK
    ]
};

/**
 * @desc Caisse stock kindb
 */

export const RapportCaisseKinDBViewLayoutPath = {
    ...RapportCaisseKinDBRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportCaisseKinDBViewLayout,
    element: <Report.RapportCaisseKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Institution financiere stock kindb
 */

export const RapportInstitutionFinanciereKinDBViewLayoutPath = {
    ...RapportInstitutionFinanciereKinDBRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportInstitutionFinanciereKinDBViewLayout,
    element: <Report.RapportInstitutionFinanciereKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Balance stock kindb
 */

export const RapportBalanceKinDBViewLayoutPath = {
    ...RapportBalanceKinDBRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportBalanceKinDBViewLayout,
    element: <Report.RapportBalanceKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Agent stock kindb
 */

export const RapportAgentKinDBViewLayoutPath = {
    ...RapportAgentKinDBRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportAgentKinDBViewLayout,
    element: <Report.RapportAgentKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Partenaire stock kindb
 */

export const RapportPartenaireKinDBViewLayoutPath = {
    ...RapportPartenaireKinDBRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportPartenaireKinDBViewLayout,
    element: <Report.RapportPartenaireKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};
/**
 * @desc Liaison  kindb
 */

export const RapportLiaisonKinDBViewLayoutPath = {
    ...RapportLiaisonKinDBRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportLiaisonKinDBViewLayout,
    element: <Report.RapportLiaisonKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};
/**
 * @desc Caution  kindb
 */

export const RapportCautionKinDBViewLayoutPath = {
    ...RapportCautionKinDBRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportCautionKinDBViewLayout,
    element: <Report.RapportCautionKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};
/**
 * @desc Retrait non déclarés  kindb
 */

export const RapportRetraitNonDeclareKinDBViewLayoutPath = {
    ...RapportRetraitNonDeclareKinDBRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportRetraitNonDeclareKinDBViewLayout,
    element: <Report.RapportRetraitNonDeclareKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * Rapport Cash collecteur
 */

export const FondsRoulementCashCollecteurViewLayoutPath = {
    ...FondsRoulementCashCollecteurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.FondsRoulementCashCollecteurViewLayout,
    element: <Report.FondsRoulementCashCollecteurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Partenaire cash collecteur
 */

export const RapportPartenaireCashCollecteurViewLayoutPath = {
    ...RapportPartenaireCashCollecteurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportPartenaireCashCollecteurViewLayout,
    element: <Report.RapportPartenaireCashCollecteurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Caisse cash collecteur
 */

export const RapportCaisseCashCollecteurViewLayoutPath = {
    ...RapportCaisseCashCollecteurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportCaisseCashCollecteurViewLayout,
    element: <Report.RapportCaisseCashCollecteurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Agent cash collecteur
 */

export const RapportAgentCashCollecteurViewLayoutPath = {
    ...RapportAgentCashCollecteurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportAgentCashCollecteurViewLayout,
    element: <Report.RapportAgentCashCollecteurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Inst. Financière cash collecteur
 */

export const RapportInstitutionFinanciereCashCollecteurViewLayoutPath = {
    ...RapportInstitutionFinanciereCashCollecteurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportInstitutionFinanciereCashCollecteurViewLayout,
    element: <Report.RapportInstitutionFinanciereCashCollecteurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Balance cash collecteur
 */

export const RapportBalanceCashCollecteurViewLayoutPath = {
    ...RapportBalanceCashCollecteurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportBalanceCashCollecteurViewLayout,
    element: <Report.RapportBalanceCashCollecteurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Liaison cash collecteur
 */

export const RapportLiaisonCashCollecteurViewLayoutPath = {
    ...RapportLiaisonCashCollecteurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportLiaisonCashCollecteurViewLayout,
    element: <Report.RapportLiaisonCashCollecteurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * Rapport revendeur
 */

/**
 * @desc Vente Revendeur
 */

export const VenteRevendeurViewLayoutPath = {
    ...VenteRevendeurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportVenteRevendeurViewLayout,
    element: <Report.RapportVenteRevendeurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Limite de crédit Revendeur
 */

export const AgentRevendeurViewLayoutPath = {
    ...RapportAgentRevendeurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportAgentRevendeurViewLayout,
    element: <Report.RapportAgentRevendeurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Caisse Revendeur
 */

export const CaisseRevendeurViewLayoutPath = {
    ...RapportCaisseRevendeurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportCaisseRevendeurViewLayout,
    element: <Report.RapportCaisseRevendeurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Balance Revendeur
 */

export const RapportBalanceRevendeurViewLayoutPath = {
    ...RapportBalanceRevendeurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportBalanceRevendeurViewLayout,
    element: <Report.RapportBalanceRevendeurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Commission Revendeur
 */

export const RapportCommissionRevendeurViewLayoutPath = {
    ...RapportCommissionRevendeurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportCommissionRevendeurViewLayout,
    element: <Report.RapportCommissionRevendeurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Recruteur Revendeur
 */

export const RapportRecruteurRevendeurViewLayoutPath = {
    ...RapportRecruteurRevendeurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportRecruteurRevendeurViewLayout,
    element: <Report.RapportRecruteurRevendeurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};
/**
 * @desc Recruteur Revendeur
 */

export const RapportLiaisonRevendeurViewLayoutPath = {
    ...RapportLiaisonRevendeurRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportLiaisonRevendeurViewLayout,
    element: <Report.RapportLiaisonRevendeurViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};
/**
 * @desc Evolution Balances
 */

export const RapportEvolutionBalanceOrganisationViewLayoutPath = {
    ...RapportEvolutionBalanceOrganisationRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportEvolutionBalanceOrganisationViewLayout,
    element: <Report.RapportEvolutionBalanceOrganisationViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_EVOLUTION_BALANCE
    ]
};

/**
 * @desc Rapport Stock Organisation
 */

export const RapportEvolutionStockOrganisationViewLayoutPath = {
    ...RapportEvolutionStockOrganisationViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportStockEvolutionOrganisationViewLayout,
    element: <Report.RapportStockEvolutionOrganisationViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_EVOLUTION_CAISSE_EMONEY
    ]
};

/**
 * @desc Rapport Caisse Emoney Organisation
 */

export const RapportCaisseEmoneyOrganisationViewLayoutPath = {
    ...RapportCaisseEmoneyOrganisationViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportCaisseEmoneyOrganisationViewLayout,
    element: <Report.RapportCaisseEmoneyOrganisationViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_EVOLUTION_CAISSE_EMONEY
    ]
};

/**
 * @desc Rapport Gain et perte change
 */

export const RapportGainPerteChangePath = {
    ...RapportGainPerteChangeRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportGainPerteChangeKinDBViewLayout,
    element: <Report.RapportGainPerteChangeKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GAIN_PERTE_CHANGE
    ]
};

/**
 * @desc Rapport Gain et perte Vente
 */

export const RapportGainPerteVentePath = {
    ...RapportGainPerteVenteRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportGainPerteVenteKinDBViewLayout,
    element: <Report.RapportGainPerteVenteKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GAIN_PERTE_VENTE,
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

/**
 * @desc Rapport Regularisation
 */

export const RapportRegularisationProduitPath = {
    ...RapportRegularisationProduitRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportRegularisationProduitViewLayout,
    element: <Report.RapportRegularisationProduitViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

export const RapportRegularisationCaissePath = {
    ...RapportRegularisationCaisseRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportRegularisationCaisseViewLayout,
    element: <Report.RapportRegularisationCaisseViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

export const RapportRegularisationStockPath = {
    ...RapportRegularisationStockRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportRegularisationStockViewLayout,
    element: <Report.RapportRegularisationStockViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

export const RapportComptabilisationKinDBPath = {
    ...RapportComptabilisationKinDBRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportComptabilisationKinDBViewLayout,
    element: <Report.RapportComptabilisationKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION.SERIVCE_CODE_RAPPORT
            .LECTURE_RAPPORT_GERANT
    ]
};

export const BasePerformanceKinDBPath = {
    ...BasePerformanceKinDBRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Report.RapportBaseKinDBViewLayout,
    element: <Report.RapportBaseKinDBViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_MODULE_ADMINISTRATION
            .RAPPORT_EVOLUTION_CLIENT_MARCHAND.RAPPORT_EVOLUTION_CLIENT_MARCHAND
    ]
};
